
/*
1. Show dark sticky menu when the user has scrolled down (below the header height) and then scrolls back up
2. Hide sticky menu when the user scrolls down again
3. Fade dark sticky menu into a regular transparent top menu when the user scrolls to the top
 */
var $header = $('header');
var headerHeight = $header.height();
var lastScrollTop = 0;

$(window).scroll(function(e) {
    var scrollTop = $(this).scrollTop();
    if (scrollTop === 0) {
        $header.removeClass('sticky show animate');
    } else if (scrollTop > headerHeight) {
        if (scrollTop < lastScrollTop) { // Scrolling up
            $header.addClass('sticky animate show')
        } else {
            $header.addClass('sticky').removeClass('show');
        }
    }
    setTimeout(() => {
        if (scrollTop === 0) {
            $header.css({
                "position": "absolute",
                "top": 0,
                "left": 0,
                "right": 0,
            });
        } else {
            $header.css({
                "position": "fixed",
                "top": 0,
                "left": 0,
                "right": 0,
            });
        }
    }, 500)

    lastScrollTop = scrollTop;
});
