$(".easeepay .information-lightbox-icon").click(function(e) {
    e.preventDefault();
    const $this = $(this);

    $this.parents("p.facility-key").find(".information-lightbox").toggleClass("show");
})

$(".easeepay .information-lightbox .close-icon").click(function(e) {
    e.preventDefault();
    const $this = $(this);

    $this.parents("p.facility-key").find(".information-lightbox").removeClass("show");
})

$(document).on("click", function(event){
    if((!$(event.target).closest(".information-lightbox").length) && (!$(event.target).closest(".information-lightbox-icon").length) && (!$(event.target).closest(".information-lightbox .close-icon").length ) ){
        $(".easeepay .information-lightbox").removeClass("show");
    }
})