$('.require a[href="#"]').click(function(e) {
    e.preventDefault();
})

$('header .menu-button').click(function(e) {
    e.preventDefault();

    openMenu();
});

$('#page-close-main-menu').click(function(e) {
    e.preventDefault();
    closeMenu();
});

$('nav#main-menu').click(function(e) {
    if ($(e.target).parents('nav').length) {
        return;
    }

    closeMenu();
});

// Open/close dropdown on mobile 
$("#main-menu ul li svg.menu-dropdown-arrow-icon").click(function(e){
    e.preventDefault();
    const $this = $(this);

    const $ul = $this.next("ul").length > 0 ? $this.next("ul") : $this.next().find('ul');

    $this.toggleClass("dropdown-open");
    if($this.hasClass("dropdown-open")) {
        $ul.slideDown();
    } else {
        $ul.slideUp();
    } 
})

$("#main-menu ul li a[href='#pll_switcher']").click(function(e){
    e.preventDefault();
    const $svg = $(this).siblings('svg.menu-dropdown-arrow-icon');

    const $ul = $svg.next().find('ul');

    $svg.toggleClass("dropdown-open");
    if($svg.hasClass("dropdown-open")) {
        $ul.slideDown();
    } else {
        $ul.slideUp();
    }
})

// Show current active child item
if($('nav#main-menu ul li.current-menu-parent').length > 0) {
    const $currentActive = $("nav#main-menu ul li.current-menu-parent");

    if(window.innerWidth > 1000) {
        const $nonActive = $("nav#main-menu ul li.menu-item-has-children:not('.current-menu-parent')")

        $nonActive.hover(function() {
            $currentActive.children("ul").addClass("hideChildUl")
        });
        $nonActive.mouseleave(function() {
            $currentActive.children('ul').removeClass("hideChildUl");
        })
    } 
    $currentActive.find("svg.menu-dropdown-arrow-icon").addClass("dropdown-open");
    $currentActive.find("ul").css("display", "block");
};

export function openMenu() {
    $('nav#main-menu').addClass('show');
    setTimeout(function() {
        $('nav').addClass('animate-show');
        $('nav.main .menu > ul > li:first-child a').focus();
    }, 10);
}

export function closeMenu() {
    $('nav#main-menu').removeClass('animate-show');
    setTimeout(function() {
        $('nav#main-menu').removeClass('show');
    }, 300);
}
