import Glide from "@glidejs/glide";
import { intersectionObserve } from "./_intersection-observer";

var $colorPreview = $('.product-color-preview');

$('input').click(function (e){
    var parentContainer = e.currentTarget;
    $(e.target).parents('.product-color-preview').find('.image-front').toggle()
    $(e.target).parents('.product-color-preview').find('.image-side').toggle()
})

function initProductColorSlider(id) {
    var colorPreviewGlide = new Glide('#' + id, {
        type: 'carousel',
        startAt: 0,
        perView: 1,
        gap: 0,
        animationDuration: 0,
        animationTimingFunc: 'cubic-bezier(0.465, 0.840, 0.440, 1.000)'
    });

    var images = document.getElementById(id);
    if (images && images.querySelector('.glide__slides').children.length > 1) {
        colorPreviewGlide.mount();

        var glideNavigation = images.querySelector('.glide-navigation');

        if (glideNavigation) {
            colorPreviewGlide.on('run', function (e) {
                var items = glideNavigation.querySelectorAll('a');
                items.forEach(function(item) {
                    item.classList.remove('active');
                });

                items[colorPreviewGlide.index].classList.add('active');
                var slide = images.querySelectorAll('.glide__slide:not(.glide__slide--clone)')[colorPreviewGlide.index];
                var title = slide.getAttribute('data-title');
                var titleElement = images.getElementsByClassName('color-title')[0];
                titleElement.innerText = title;
            });

            glideNavigation.querySelectorAll('a').forEach(function (link) {
                link.addEventListener('click', function (e) {
                    e.preventDefault();
                    colorPreviewGlide.go('=' + this.getAttribute('data-id'));
                });
            });
        }
    }
}

window.initProductColorSlider = initProductColorSlider;