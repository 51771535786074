const $expandSellingPoints = $(".selling-points .selling_point");
const $expandSellingPointsHidden = $(".selling-points .selling_point:nth-child(n+5)");
const $expandPointsMobile = $(".selling-points .expandMobile");
const $expandableSectionsMobile = $(".selling-points .selling_point > .content:not(.standard)");

if($expandSellingPoints.length !== 0) {
    // Toggle expandable content state on mobile
    $('.selling-points .expandable-section .svg-container > a').click(function(e) {
        e.preventDefault();
    
        $(this).parents('.expandable-section').toggleClass('active');
    });

    // Toggle h3 and icon state on mobile
    $expandPointsMobile.click(function(e) {
        e.preventDefault();

        if ($(this).hasClass("active")) {
            $(this).removeClass("active");
            $(this).prev("h3").removeClass("active");
        } else {
            $(this).addClass("active");
            $(this).prev("h3").addClass("active");
        }
    })

    // Remove hover underline on inline links with underline
    $(".selling-points .selling_point .content span").each(function() {
        var $this = $(this);
        if($this.find("a").length > 0) {
            if($this.css("text-decoration").indexOf("underline") !== -1) {
                $this.find("a").addClass("underlined-inline-link");
            }
        } else {
            if($this.css("text-decoration").indexOf("underline") !== -1) {
                $this.parents("a").addClass("underlined-inline-link");
            }
        }
    });
}
