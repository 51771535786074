$('.podcast-episodes .top').click(function(e) {
    e.preventDefault();
    $(this).parents('.episode-wrapper').find($('.expand-content')).toggleClass('active');
    $(this).parents('.episode-wrapper').find($('svg')).toggleClass('active');
    $(this).parents('.episode-wrapper').find($('h3')).toggleClass('active');
    $(this).parents('.episode-wrapper').find($('.external-spacer')).toggleClass('active');
    $(this).parents('.episode-wrapper').find($('.progress')).toggleClass('active');
});



function calculateTotalValue(length) {
    var minutes = Math.floor(length / 60),
        seconds_int = length - minutes * 60,
        seconds_str = seconds_int.toString(),
        seconds = seconds_str.substr(0, 2),
        time = minutes + ':' + seconds

    return time;
}

function calculateCurrentValue(currentTime) {
    var current_hour = parseInt(currentTime / 3600) % 24,
        current_minute = parseInt(currentTime / 60) % 60,
        current_seconds_long = currentTime % 60,
        current_seconds = current_seconds_long.toFixed(),
        current_time = (current_minute < 10 ? "0" + current_minute : current_minute) + ":" + (current_seconds < 10 ? "0" + current_seconds : current_seconds);

    return current_time;
}


function initProgressBar(player,index) {
    //TODO: session cookie that stores progress when paused. onload checks if a cookie for each player exists and puts in value if exists
    var progressbar = document.getElementById('progress-'+index)
    progressbar.oninput = function(){

        var percent = progressbar.value
        player.currentTime = percent * player.duration;

        renderTime(player,index)
    };




}
function renderTime(player,index){
    var length = player.duration
    var current_time = player.currentTime;
    var progress_dot = document.getElementById('progress-dot-'+index)
    var filler = document.getElementById('filler-'+index)
    // calculate total length of value
    var totalLength = calculateTotalValue(length)
    $("#end-"+index).html(totalLength);

    // calculate current value time
    var currentTime = calculateCurrentValue(current_time);
    $('#start-'+index).html(currentTime);
    var progressbar = document.getElementById('progress-'+index)
    progress_dot.style.opacity = '1'
    progress_dot.style.left = progressbar.value *100-0.2+'%';
    progressbar.value = (player.currentTime / player.duration);
    filler.style.width = progressbar.value *100+'%';
    if (player.currentTime == player.duration) {
        $('#play-'+index).removeClass('pause');
    }
}

var players = document.getElementsByClassName('player')
function initListeners(player,index){
    var playBtn = document.getElementById('play-' + index);
    const topContent = document.querySelectorAll('.top')[index];
    const progressFiller = document.querySelectorAll('.progress-filler')[index];
    var topParents = topContent.closest('.internal_podcasts')

    if (topParents) {

        topContent.addEventListener('mouseenter', function () {
            var expandContent = document.querySelectorAll('.expand-content')[index];
            if (expandContent.classList.contains('active')) {
                progressFiller.style.backgroundColor = '#005AFF';
            }
            if (playBtn.classList.value === 'pause') {
                progressFiller.style.backgroundColor = '#E6E7E8';
            } else if (playBtn.classList.value === 'play') {
                progressFiller.style.backgroundColor = '#E6E7E8';
            } else {

                progressFiller.style.backgroundColor = '#005AFF';
            }


        })
        topContent.addEventListener('mouseleave', function () {
            var expandContent = document.querySelectorAll('.expand-content')[index];
            if (playBtn.classList.value === 'pause') {
                progressFiller.style.backgroundColor = '#E6E7E8';
            } else if (playBtn.classList.value === 'play') {
                progressFiller.style.backgroundColor = '#E6E7E8';
            } else if (expandContent.classList.contains('active')) {
                progressFiller.style.backgroundColor = '#005AFF';
            } else {
                progressFiller.style.backgroundColor = '#E6E7E8';
            }
        })
    }
}
function initPlayer(player,index) {

    var playBtn = document.getElementById('play-' + index);

    var isPlaying = false
    if (playBtn != null) {
        playBtn.addEventListener('click', function() {
            togglePlay()
            progressFiller.style.backgroundColor = '#E6E7E8';
        });
    }

    function togglePlay() {
        if (player.paused === false) {

            player.pause();
            isPlaying = false;
            playBtn.classList.add('play');
            playBtn.classList.remove('pause');


        } else {
            // Pause all other players
            for (var i = 0; i < players.length; i++) {
                var p = players[i];
                p.pause();
                var pBtn = document.getElementById('play-' + i);
                pBtn.classList.add('play');
                pBtn.classList.remove('pause');
            }

            initProgressBar(player,index)
            player.play();
            playBtn.classList.remove('play');
            playBtn.classList.add('pause');
            isPlaying = true;
        }
    }
    player.addEventListener('timeupdate',function(){
       renderTime(player,index)
    })
}
for(var i = 0; i < players.length; i++){
    var player = document.getElementById('player-'+i)

    initPlayer(players[i],i)
    initListeners(players[i],i)

}


