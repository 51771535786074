import Fuse from 'fuse.js'

let isLoadingKnowledgeBase = false;
let knowledgeBaseArticles = null;
const $supportSearch = $('#support-search');
const $supportSearchInput = $('#support-search input');
const $supportSearchResults = $('#support-search .results');
const salesforceBaseURL = 'https://support.easee.com/help/s/';
const zendeskBaseURL = 'https://easee.zendesk.com/hc/';
const language = $supportSearch.data('language') || '';
let resultIndex = -1;
const supportSystem = $supportSearch.data('supportSystem') || 'salesforce';
const isSalesforce = supportSystem === 'salesforce';

const fuseOptions = {
    includeScore: true,
    keys: ['title', 'body']
}

$supportSearchInput.focus(() => {
    if (isSalesforce && !knowledgeBaseArticles && !isLoadingKnowledgeBase) {
        isLoadingKnowledgeBase = true;
        $.get('/cache/knowledgebase.json').then(function(data) {
            if (data) {
                knowledgeBaseArticles = data;
                supportSearch($supportSearchInput.val());
            }
        }).always(() => {
            isLoadingKnowledgeBase = false;
        });
    }

    resultIndex = -1;
    supportSearch($supportSearchInput.val());
}).blur(() => {
    setTimeout(function() {
        $supportSearchResults.hide();
    }, 500);
});

const inputTimeout = 200;
let inputTimer = null;
$supportSearchInput.keyup(function(e) {
    const searchString = $(this).val();

    if (isSalesforce) {
        const specialKeys = [13, 38, 40];
        if (specialKeys.indexOf(e.keyCode) !== -1) {
            handleKeyEvent(e);
            return;
        }
        supportSearch(searchString);

        resultIndex = -1;
        moveResultSelection(0);
    } else {
        if (inputTimer) {
            clearTimeout(inputTimer);
        }

        inputTimer = setTimeout(function () {
            const specialKeys = [13, 38, 40];
            if (specialKeys.indexOf(e.keyCode) !== -1) {
                handleKeyEvent(e);
                return;
            }
            supportSearch(searchString);

            resultIndex = -1;
            moveResultSelection(0);
        }, inputTimeout);
    }
});

function supportSearch(input) {
    if (input.length < 2) {
        $supportSearchResults.hide();
        return;
    }

    if (isSalesforce) {
        const list = knowledgeBaseArticles || [];
        const languageFilteredList = list.filter(item => !language || item.language === language);
        const fuse = new Fuse(languageFilteredList, fuseOptions);
        const results = fuse.search(input);

        const resultList = results.map(result => '<li><a href="' + salesforceBaseURL + 'article/' + result.item.url + '?language=' + language + '">' + result.item.title + '</a></li>').slice(0, 10).join('');
        $supportSearchResults.find('ul').html(resultList);

        if (results.length > 0) {
            $supportSearchResults.show();
        } else {
            $supportSearchResults.hide();
        }

        if (resultIndex >= results.length) {
            resultIndex = -1;
            moveResultSelection(0)
        }
    } else {
        let data = new FormData();
        data.append('action', 'zendesk_search');
        data.append('s', input);
        data.append('locale', language);

        $.ajax({
            url: easee_scripts.ajax_url,
            type: 'POST',
            data: data,
            cache: false,
            processData: false,
            contentType: false,
            error: function () {
                $supportSearchResults.hide();
            },
            complete: function (data) {
                const results = data.responseJSON.results;

                if (results) {
                    const resultList = results.map(result => '<li><a href="' + result.url + '">' + result.title + '</a></li>').slice(0, 10).join('');
                    $supportSearchResults.find('ul').html(resultList);

                    if (results.length > 0) {
                        $supportSearchResults.show();
                    } else {
                        $supportSearchResults.hide();
                    }

                    if (resultIndex >= results.length) {
                        resultIndex = -1;
                        moveResultSelection(0)
                    }
                } else {
                    $supportSearchResults.hide()
                }
            }
        });
    }
}

function handleKeyEvent(e) {
    e.preventDefault();

    switch (e.keyCode) {
        case 13: // enter
            activateSelection(e);
            break;
        case 38: // key up
            moveResultSelection(-1);
            break;
        case 40: // key down
            moveResultSelection(1);
            break;
    }
}

function moveResultSelection(direction) {
    if (direction < 0) {
        resultIndex = Math.max(resultIndex - 1, -1);
    } else if (direction > 0) {
        resultIndex = Math.min(resultIndex + 1, $supportSearchResults.find('li').length - 1);
    }

    $supportSearchResults.find('li a').removeClass('focused');

    if (resultIndex > -1) {
        $supportSearchResults.find('li:nth-child(' + (resultIndex + 1) + ') a').addClass('focused');
    }
}

function activateSelection(e) {
    if (resultIndex === -1) {
        if (isSalesforce) {
            window.location = salesforceBaseURL + 'global-search/' + e.target.value;
        } else {
            window.location = zendeskBaseURL + language + '/search?query=' + e.target.value;
        }
        return;
    }

    $supportSearchResults.find('li:nth-child(' + (resultIndex + 1) + ') a')[0].click();
}
