
$('.documentation p a').click(function(e) {
    e.preventDefault();

    var $parentLi = $(this).parent().parent('li');

    // Is the current selection active before the click?
    var isActive = $parentLi.hasClass('active');

    // Hide all sections
    $parentLi.parent().find('> li').removeClass('active').find('> ul').slideUp();

    // If the section was not active before, make it active
    if (!isActive) {
        $parentLi.addClass('active');
        $(this).parent().siblings('ul').slideDown('fast');
    }
});

$('.documentation .language-selector .current-language a').click(function(e) {
    e.preventDefault();

    var $parent = $(this).parent();
    var isOpen = $parent.hasClass('open');
    var $ul = $('.documentation .language-selector ul');

    if (isOpen) {
        $parent.removeClass('open');
        $ul.slideUp();
    } else {
        $parent.addClass('open');
        $ul.slideDown('fast');
    }
});

$('.documentation .language-selector ul a').click(function(e) {
    e.preventDefault();

    $(this).parent().siblings().removeClass('active');
    $(this).parent().addClass('active');

    var $selector = $('.documentation .language-selector');
    $selector.find('.current-language').removeClass('open');
    $selector.find('ul').hide();

    const language = $(this).data()['language'];
    const fullLanguage = $(this).data()['fullLanguage'];

    changeLanguage(language, fullLanguage);

});

function changeLanguage(language) {
    const $documentation = $('.documentation');

    var $a = $documentation.find('.language-selector ul a[data-language="' + language + '"]');
    var fullLanguage = $a.data('fullLanguage');
    var downloadText = $a.data('downloadText') || 'Download PDF';

    $documentation.find('.language-selector ul li').removeClass('active');
    $documentation.find('.language-selector .current-language a span').text(fullLanguage);
    $documentation.find('.languages > div').hide();
    $documentation.find('.languages div[data-language="' + language + '"]').show();
    $documentation.find('.metadata h3').text(downloadText);
    $a.parent().addClass('active');
}

switch (document.documentElement.lang) {
    case 'da':
    case 'da-DK':
        changeLanguage('dansk');
        break;
    case 'de':
    case 'de-DE':
        changeLanguage('deutsch');
        break;
    case 'es':
    case 'es-ES':
        changeLanguage('espanol');
        break;
    case 'fi':
    case 'fi-FI':
        changeLanguage('suomi');
        break;
    case 'fr':
    case 'fr-BE':
    case 'fr-FR':
    case 'fr-LU':
    case 'fr-MC':
        changeLanguage('francais');
        break;
    case 'it':
    case 'it-IT':
    case 'it-CH':
        changeLanguage('italiano');
        break;
    case 'nb':
    case 'nb-NO':
        changeLanguage('norsk');
        break;
    case 'nl':
    case 'nl-NL':
    case 'nl-BE':
        changeLanguage('nederlands');
        break;
    case 'sv':
    case 'sv-SE':
        changeLanguage('svenska');
        break;
    case 'pl':
    case 'pl-PL':
        changeLanguage('polski');
        break;
    default:
        break;
}
