import bodymovin from 'lottie-web'

var animations = document.querySelectorAll('.lottie-animation-model');
var animationObject = {
    animation1: "",
    animation2: "",
    animation3: "",
    animation4: "",
    animation5: ""
}

function setup() {
    animations.forEach((element, i) => loadAnimation(element, i));
}

setup();
setTimeout(setup, 1000);
setTimeout(setup, 3000);

// set up animation object for each element
function loadAnimation(element, i) {
    if (element.classList.contains('loaded')) {
        return;
    }
    var animationName = element.getAttribute('data-animation');

    var index = i+1
    animationObject["animation" + index]= bodymovin.loadAnimation({
        container: element, // Required
        path: '/wp-content/themes/grensesnitt/animations/' + animationName + '.json', // Required
        renderer: 'svg', // Required
        loop: false, // Optional
        autoplay: false, // Optional
        name: "", // Name for future reference. Optional.
    });

    element.classList.add('loaded');

    goToFrame();
}

// add event listeners for click/touch and move/drag
let previousX;
function mouseMoveWhilstDown(target, whileMove) {
    var endMove = function () {
        window.removeEventListener('mousemove', whileMove);
        window.removeEventListener('mouseup', endMove);
        window.removeEventListener('touchmove', whileMove);
        window.removeEventListener('touchend', endMove);

        target.forEach(function(el) {
            el.classList.remove('grabbing');
        });
    };
    
    target.forEach(function(el) {
        el.addEventListener('mousedown', function (event) {
            previousX = event.clientX;
            event.stopPropagation();
            window.addEventListener('mousemove', whileMove);
            window.addEventListener('mouseup', endMove);
            el.classList.add('grabbing');
        });
        el.addEventListener('touchstart', function (event) {
            previousX = event.touches[0].clientX;
            event.stopPropagation();
            window.addEventListener('touchmove', whileMove);
            window.addEventListener('touchend', endMove);   
        });
    });
}
mouseMoveWhilstDown(
    document.querySelectorAll('.lottie-animation-model'),
    function (event) { goToFrame(event); }
);

var currentFrameObject = {
    currentFrame1: 30,
    currentFrame2: 30,
    currentFrame3: 30,
    currentFrame4: 30,
    currentFrame5: 30
}

// go to same frame on all animation elements
function goToFrame(e) {
    for(let i = 0; i < 5; i++) {
        var index = i + 1;
        if (!animationObject["animation" + index]) {
            return;
        }
        animationObject["animation" + index].goToAndStop(30, true);
    }

    if(e) {  
        var currentX = e.pageX
        if(!currentX) {
            currentX = e.touches[0].clientX             
        }
        var diffX = previousX - currentX;
        var frame;

        for(let i = 0; i < 5; i++) {
            var index = i+1;

            if(diffX > 0) {
                frame = Math.round(currentFrameObject["currentFrame" + index] + diffX / 9);
            } else {
                var diffXNeg = Math.abs(diffX)
                frame = Math.round(currentFrameObject["currentFrame" + index] - diffXNeg / 9);
            }
        
            if(frame > 60) {
                animationObject["animation" + index].goToAndStop(60, true);
            } else if(frame < 0) {
                animationObject["animation" + index].goToAndStop(0, true);
            } else {
                animationObject["animation" + index].goToAndStop(frame, true);
            }   

            currentFrameObject["currentFrame" + index]  = animationObject["animation" + index].currentFrame;
        }
        previousX = currentX;
    }
}

// change which color model is displayed
$(".model-view-animation .color-navigation a").click(function(e) {
    e.preventDefault();
    $(".lottie-animation-model").css("display","none");
    $(".model-view-animation .color-navigation li").removeClass("active");
    $(".model-view-animation .color-navigation li a").removeClass("active");
    const colorNameContainer = document.querySelectorAll(".model-view-animation .mobile-color-name span");
    if(e.target.nodeName === "SPAN") {
        e.target.parentNode.parentNode.classList.add("active")
        animations.forEach(animation => animation.dataset.name === e.target.parentNode.className ? animation.style.display = "block" : animation.style.display = "none")
        colorNameContainer.forEach(color => color.dataset.name === e.target.parentNode.className ? color.classList.add("active") : color.classList.remove("active") )
    } else {
        e.target.parentNode.classList.add("active")
        animations.forEach(animation => animation.dataset.name === e.target.className ? animation.style.display = "block" : animation.style.display = "none")
        colorNameContainer.forEach(color => color.dataset.name === e.target.className ? color.classList.add("active") : color.classList.remove("active") )
    }
});
