$('.questions-answers .qa p a').click(function(e) {
    e.preventDefault();

    // Is the current selection active before the click?
    var isActive = $(this).parents('li').hasClass('active');

    // Hide all sections
    $(this).parents('ul').find('> li').removeClass('active').find('ul').slideUp();

    // If the section was not active before, make it active
    if (!isActive) {
        $(this).parents('li').addClass('active');
        $(this).parent().siblings('ul').slideDown('fast');
    }
});

$('.questions-answers .qa .question h4 a').click(function(e) {
    e.preventDefault();

    // Is the current selection active before the click?
    var isActive = $(this).parents('li.question').hasClass('active');

    // Hide all sections
    $(this).parents('ul.questions').find('> li').removeClass('active').find('.answer').slideUp();

    // If the section was not active before, make it active
    if (!isActive) {
        $(this).parents('li.question').addClass('active');
        $(this).parent().siblings('.answer').slideDown('fast');
    }
});