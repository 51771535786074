import Glide from "@glidejs/glide";
import { intersectionObserve } from "./_intersection-observer";
import bodymovin from 'lottie-web'

var $imageTexts = $('.image-text');

if ($imageTexts.length > 1) {
    let preventOverlapping = false;
    let successors;
    $imageTexts.each(function(index, el) {
        if(!$(el).prev().hasClass('image-text')) {
            successors = $(el).nextUntil('section:not(.image-text)').toArray();
            successors.unshift(el);
            if($(successors).length > 0) {
                $(successors).each(function(index, elem) {
                    if($(elem).hasClass('prevent-overlapping')){
                        preventOverlapping = true;
                    }
                });
            }
        }
    });
    if(!preventOverlapping) {
        $(window).scroll(function (e) {
            var scrollTop = $(e.target).scrollTop();
            var firstTop = $($imageTexts[0]).offset().top;
            var lastTop = $($imageTexts[$imageTexts.length - 1]).offset().top;

            $imageTexts.each(function(index, el) {
                var $imageText = $(el);
                var offsetTop = $imageText.offset().top;
                var height = $imageText.outerHeight();
                var $figure = $imageText.find('figure');
                var $overlay = $imageText.find('dark-overlay');
    
                if (window.innerWidth > 1000 && scrollTop > firstTop && scrollTop <= lastTop) {
                    var diff = scrollTop + height - offsetTop;
    
                    $figure.addClass('fixed');
                    if (index > 0) {
                        $figure.css('height', '' + Math.min(diff, height) + 'px');
                    }
    
                    if (index > 0) {
                        var $prevOverlay = $($imageTexts[index - 1]).find('.dark-overlay');
                        $prevOverlay.css('opacity', Math.min(diff / height / 2, 0.5));
                    }
    
                } else {
                    $figure.removeClass('fixed');
                    $figure.css('height', '');
                    $overlay.css('opacity', 0);
                }
            });
        });
    }
}


$imageTexts.each(function(index, el) {
    // Play videos when they get visible
    var videos = el.querySelectorAll('video');
    intersectionObserve(videos, 0, function(element, intersectionRatio) {
        if (intersectionRatio > 0) {
            element.play();
        }
    });
    
    let animations = el.querySelectorAll('.animation');
    let animation;

    function setup() {
        animations.forEach(element => loadAnimation(element));
    }

    setup();
    setTimeout(setup, 1000);
    setTimeout(setup, 3000);

    function loadAnimation(element) {
        if (element.classList.contains('loaded')) {
            return;
        }

        var data = element.querySelector('.data code');

        let alt_title = element.getAttribute('data-title') ?? '';

        animation = bodymovin.loadAnimation({
            container: element, // Required
            animationData: JSON.parse(data.innerHTML),
            renderer: 'svg', // Required
            loop: true, // Optional
            autoplay: true, // Optional
            name: "", // Name for future reference. Optional.
            rendererSettings: {
                title: alt_title,
            },
        });

        element.classList.add('loaded');
    }
});

function initImageTextSlider(id) {
    var imageTextGlide = new Glide('#' + id, {
        type: 'slider',
        startAt: 0,
        perView: 1,
        gap: 0,
        animationDuration: 0,
        animationTimingFunc: 'cubic-bezier(0.465, 0.840, 0.440, 1.000)'
    });

    var images = document.getElementById(id);
    if (images && images.querySelector('.glide__slides').children.length > 1) {
        imageTextGlide.mount();

        var glideNavigation = images.querySelector('.glide-navigation');

        if (glideNavigation) {
            imageTextGlide.on('run', function (e) {
                var items = glideNavigation.querySelectorAll('a');
                items.forEach(function(item) {
                    item.classList.remove('active');
                });

                items[imageTextGlide.index].classList.add('active');

                var slide = images.querySelectorAll('.glide__slide:not(.glide__slide--clone)')[imageTextGlide.index];
                var title = slide.getAttribute('data-title');
                var titleElement = images.getElementsByClassName('mobile')[0];
                var titleElementDT = images.querySelector('.image-text .title');
                if(titleElement) {
                    titleElement.innerText = title;
                } else if(titleElementDT) {
                    titleElementDT.innerText = title;
                }
            });

            glideNavigation.querySelectorAll('a').forEach(function (link) {
                link.addEventListener('click', function (e) {
                    e.preventDefault();
                    imageTextGlide.go('=' + this.getAttribute('data-id'));
                });
            });
        }
    }
}

window.initImageTextSlider = initImageTextSlider;