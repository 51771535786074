import bodymovin from 'lottie-web'
import { intersectionObserve } from "./_intersection-observer";

const arrows = document.querySelectorAll('.arrow-icon');

let index = 0;

intersectionObserve(arrows, 0, (element, intersectionRatio) => {
    (function(el) {
        const waveEl = el.getElementsByClassName('wave')[0];
        const hoverEl = el.getElementsByClassName('hover')[0];

        if (intersectionRatio > 0 && element.classList.contains('loaded')) {
            return;
        } else if (intersectionRatio <= 0) {
            waveEl.style.display = 'block';
            hoverEl.style.display = 'none';
            element.classList.remove('loaded');

            // Destroy existing animations
            const idx = el.getAttribute('data-arrow-index');
            bodymovin.destroy('wave-' + idx);
            bodymovin.destroy('hover-' + idx);
            el.removeAttribute('data-arrow-index');

            return;
        }

        element.classList.add('loaded');

        let waveAnimation;
        let hoverAnimation;

        if (el.getAttribute('data-arrow-index') === null) {
            el.setAttribute('data-arrow-index', index);
            waveAnimation = createWaveAnimation(waveEl, index);
            hoverAnimation = createHoverAnimation(hoverEl, index);
            index++;
        }


        var hoverElement = null;
        var parentElement = element;
        while (parentElement) {
            if (parentElement.tagName === 'A' || parentElement.tagName === 'BUTTON') {
                hoverElement = parentElement;
                break
            }

            parentElement = parentElement.parentNode;
        }

        if (hoverElement) {
            $(hoverElement).hover(function () {
                if (!waveAnimation.isPaused) {
                    return;
                }

                waveEl.style.display = 'none';
                hoverEl.style.display = 'block';

                hoverAnimation.goToAndPlay(1, true);
            }, function () {
            });
        }
    })(element);

});

const createWaveAnimation = function(element, index) {
    return bodymovin.loadAnimation({
        container: element, // Required
        path: '/wp-content/themes/grensesnitt/animations/arrow-wave.json', // Required
        renderer: 'svg', // Required
        loop: false, // Optional
        autoplay: true, // Optional
        name: "wave-" + index, // Name for future reference. Optional.
    });
};

const createHoverAnimation = function(element) {
    return bodymovin.loadAnimation({
        container: element, // Required
        path: '/wp-content/themes/grensesnitt/animations/arrow-hover.json', // Required
        renderer: 'svg', // Required
        loop: false, // Optional
        autoplay: false, // Optional
        name: "hover-" + index, // Name for future reference. Optional.
    });
};
