var $typeOptions = $('.brand-image-library .types .type-options');
var $activeType = $('.brand-image-library .types .active');
var $orientationOptions = $('.brand-image-library .orientations .orientation-options');
var $activeOrientation = $('.brand-image-library .orientations .active');
var $imagesContent = $('.brand-image-library .images-content');
var $images = $imagesContent.children('.image.swipe');
var $imagesCount = $images.length;
var $imagesPerPage = 18;
var $numberOfPages = Math.ceil($imagesCount / $imagesPerPage);
var $pagination = $('.brand-image-library .media-pagination');
var $categoryFilter = $('.image-library .brand-menu');
var $pageNumber;
var $displayed;
var $nextArrow = $pagination.find('a.arrow-next');
var $prevArrow = $pagination.find('a.arrow-prev');

/* $imagesContent.css({
    'min-height': $imagesContent.outerHeight() + 50 + 'px'
});
 */
for (var j = 1; j <= $numberOfPages; j++) {
    $pagination.children('ul').append("<li><a href='#' class='page' data-displayed='" + $imagesPerPage + "' data-index='" + j + "'>0" + j + "</a></li>");
    $("li:first-child").addClass('current');
    $("li:first-child a").addClass('current');
    $pagination.find('.arrow-prev').addClass('disabled');
    if ($pagination.find('li').length <= 1) {
        $pagination.find('.arrow-next').addClass('disabled');
    }
}

function setArrows() {
    if ($pagination.find('li').length > 1) {
        if ($pagination.find('li').first().hasClass('current')) {
            $pagination.children('.arrow-next-wrapper').children('.arrow-next').removeClass('disabled');
            $pagination.children('.arrow-prev-wrapper').children('.arrow-prev').addClass('disabled');
        }
        else if ($pagination.find('li').last().hasClass('current')) {
            $pagination.children('.arrow-prev-wrapper').children('.arrow-prev').removeClass('disabled');
            $pagination.children('.arrow-next-wrapper').children('.arrow-next').addClass('disabled');
        }
        else {
            $pagination.children('.arrow-prev-wrapper').children('.arrow-prev').removeClass('disabled');
            $pagination.children('.arrow-next-wrapper').children('.arrow-next').removeClass('disabled');
        }
    }
    else {
        $pagination.children('.arrow-prev-wrapper').children('.arrow-prev').addClass('disabled');
        $pagination.children('.arrow-next-wrapper').children('.arrow-next').addClass('disabled');
    }
}

function setPagination($this) {
    $this.parent('li').parent('ul').children('li').removeClass('current');
    $this.parent('li').parent('ul').children('li').children('.page').removeClass('current');
    $this.addClass('current');
    $this.parent('li').addClass('current');
    $pageNumber = $this.data('index');
    $displayed = $this.data('displayed');
}

function calculatePagination() {
    var $shownImages = $imagesContent.find('.image.swipe.show-media').length;
    var $numberOfPages = Math.ceil($shownImages / $imagesPerPage);
    $pagination.children('ul').empty();
    for (var j = 1; j <= $numberOfPages; j++) {
        $pagination.children('ul').append("<li><a href='#' class='page' data-displayed='" + $imagesPerPage + "' data-index='" + j + "'>0" + j + "</a></li>");
        $("li:first-child").addClass('current');
        $("li:first-child a").addClass('current');
    }
    $imagesContent.find('.image.swipe.show-media').hide();
    for (var j = 0; j < $imagesPerPage; j++) {
        $imagesContent.find('.image.swipe.show-media').eq(j).show();
    }
    setArrows();

    $pagination.find('a.page').click(function (e) {
        e.preventDefault();
        setPagination($(this));

        $imagesContent.find('.image.swipe.show-media').hide();
        for (var j = (($pageNumber - 1) * $displayed); j < ($pageNumber * $displayed); j++) {
            $imagesContent.find('.image.swipe.show-media').eq(j).show();
        }
        setArrows();
        $(window).scrollTop(0, 2000);
    });
}

$prevArrow.click(function (e) {
    e.preventDefault();
    $pagination.find('li.current').prev().addClass('current');
    $pagination.find('li.current').children('a.page').addClass('current');
    $pagination.find('li.current').last().children('a.page').removeClass('current');
    $pagination.find('li.current').last().removeClass('current');
    $displayed = $pagination.find('li.current').children('a.current').data('displayed');
    $pageNumber = $pagination.find('li.current').children('a.current').data('index');

    if ($('.brand-image-library .filters .types').hasClass('options-active') || $('.brand-image-library .filters .orientations').hasClass('options-active') || $categoryFilter.hasClass('options-active')) {
        $imagesContent.find('.image.swipe.show-media').hide();
        for (var j = (($pageNumber - 1) * $displayed); j < ($pageNumber * $displayed); j++) {
            $imagesContent.find('.image.swipe.show-media').eq(j).show();
        }
    }
    else {
        $imagesContent.find('.image.swipe').removeClass('show-media');
        for (var j = (($pageNumber - 1) * $displayed); j < ($pageNumber * $displayed); j++) {
            $imagesContent.find('.image.swipe').eq(j).addClass('show-media');
        }
    }
    setArrows();
    $(window).scrollTop(0, 2000);

});

$nextArrow.click(function (e) {
    e.preventDefault();
    $pagination.find('li.current').next().addClass('current');
    $pagination.find('li.current').children('a.page').addClass('current');
    $pagination.find('li.current').first().children('a.page').removeClass('current');
    $pagination.find('li.current').first().removeClass('current');
    $displayed = $pagination.find('li.current').children('a.current').data('displayed');
    $pageNumber = $pagination.find('li.current').children('a.current').data('index');

    if ($('.brand-image-library .filters .types').hasClass('options-active') || $('.brand-image-library .filters .orientations').hasClass('options-active') || $categoryFilter.hasClass('options-active')) {
        $imagesContent.find('.image.swipe.show-media').hide();
        for (var j = (($pageNumber - 1) * $displayed); j < ($pageNumber * $displayed); j++) {
            $imagesContent.find('.image.swipe.show-media').eq(j).show();
        }
    }
    else {
        $imagesContent.find('.image.swipe').removeClass('show-media');
        for (var j = (($pageNumber - 1) * $displayed); j < ($pageNumber * $displayed); j++) {
            $imagesContent.find('.image.swipe').eq(j).addClass('show-media');
        }
    }
    setArrows();
    $(window).scrollTop(0, 2000);

});

$pagination.find('a.page').click(function (e) {
    e.preventDefault();
    setPagination($(this));
    $imagesContent.find('.image.swipe').removeClass('show-media');
    for (var j = (($pageNumber - 1) * $displayed); j < ($pageNumber * $displayed); j++) {
        $imagesContent.find('.image.swipe').eq(j).addClass('show-media');
    }
    setArrows();

    $(window).scrollTop(0, 2000);
});

function filterImages($this, $class) {
    $this.parents($class).addClass("options-active");
    var $selectedFilters = [];
    var $categoryActive = $('aside.options-active');
    var $typeActive = $('.brand-image-library .filters .types.options-active');
    var $orientationActive = $('.brand-image-library  .filters .orientations.options-active');

    if ($categoryActive.length > 0) {
        var $activeTag = $categoryActive.find('a.current.active').data('target');
        $selectedFilters.push($activeTag);
    }
    else {
        var $categoriesArray = [];
        var $categories = $('.brand-menu ul').find('li');
        for (var i = 1; i < $categories.length; i++) {
            var $selectedSlug = $categories.eq(i).find('a').data('target')
            $categoriesArray.push($selectedSlug);
        }
        $selectedFilters.push($categoriesArray);
    }

    if ($typeActive.length > 0) {
        var $activeTag = $typeActive.find('li.selected a').data('slug');
        $selectedFilters.push($activeTag);
    }
    else {
        var $typesArray = [];
        var $types = $typeOptions.find('li');
        for (var i = 1; i < $types.length; i++) {
            var $selectedSlug = $types.eq(i).find('a').data('slug')
            $typesArray.push($selectedSlug);
        }
        $selectedFilters.push($typesArray);
    }
    if ($orientationActive.length > 0) {
        var $activeTag = $orientationActive.find('li.selected a').data('slug');
        $selectedFilters.push($activeTag);
    }
    else {
        var $orientationsArray = [];
        var $orientations = $orientationOptions.find('li');
        for (var i = 1; i < $orientations.length; i++) {
            var $selectedSlug = $orientations.eq(i).find('a').data('slug')
            $orientationsArray.push($selectedSlug);
        }
        $selectedFilters.push($orientationsArray);
    }

    var $imageTags = [];
    for (var i = 0; i < $images.length; i++) {
        for (var j = 0; j < $selectedFilters.length; j++) {
            if (!$.isArray($selectedFilters[j])) {
                if ($selectedFilters[j] != 'all') {
                    $match = $.inArray($selectedFilters[j], $imageTags);
                    if ($match === -1) {
                        $imageTags.push($selectedFilters[j]);
                    }
                }
            }
        }
    }
    var $imageClasses = 'null';
    for (var j = 0; j < $imageTags.length; j++) {
        $imageClasses = $imageTags.join('.');
    }

    if ($imageClasses != 'null') {
        $images.removeClass('show-media');
        $('.image.swipe').hide();
        $('.image.swipe.' + $imageClasses).addClass('show-media');
        $('.image.swipe.' + $imageClasses).show();
    }
    else {
        $('.image.swipe').show();
        $('.image.swipe').addClass('show-media');
    }
    calculatePagination();
}

$activeType.find('a').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    $typeOptions.slideToggle('fast');
    $this.toggleClass('open');
    $this.parent().toggleClass('options-open');
});

$typeOptions.find('a').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    $activeType.find('span').html($this.html());
    $typeOptions.css({
        'display': 'none'
    });
    $typeOptions.children('li.selected').removeClass('selected');
    $this.parent('li').addClass('selected');
    $this.parents('.types').find('.active').children('a').removeClass('open');
    $this.parents('.types').find('.active').removeClass('options-open');
    filterImages($(this), '.types');
});

$activeOrientation.find('a').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    $orientationOptions.slideToggle('fast');
    $this.toggleClass('open');
    $this.parent().toggleClass('options-open');
});

$orientationOptions.find('a').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    $activeOrientation.find('span').html($this.html());
    $orientationOptions.css({
        'display': 'none'
    });
    $orientationOptions.children('li.selected').removeClass('selected');
    $this.parent('li').addClass('selected');
    $this.parents('.orientations').find('.active').children('a').removeClass('open');
    $this.parents('.orientations').find('.active').removeClass('options-open');

    var $typesFilter = $('.brand-image-library .filters .types');
    var $slug = $(this).data('slug');

    filterImages($(this), '.orientations');
});

$('.brand-image-library a.image-link').hover(function () {
    var $overlay = $(this).find('.overlay');
    $overlay.css('background', 'rgba(0, 0, 0, 0.8)');
    $(this).find('.overlay svg').css({
        'opacity': 1
    });
    $(this).find('.overlay img').css({
        'opacity': 1
    });
    $(this).find('.overlay .notice').css({
        'opacity': 1
    });
}, function () {
    var $overlay = $(this).find('.overlay');
    $overlay.css('background', 'rgba(0, 0, 0, 0)');
    $(this).find('.overlay svg').css({
        'opacity': 0
    });
    $(this).find('.overlay img').css({
        'opacity': 0
    });
    $(this).find('.overlay .notice').css({
        'opacity': 0
    });
})

$categoryFilter.find('a').click(function (e) {
    e.preventDefault();
    $categoryFilter.addClass("filter-active");
    $categoryFilter.find('li').removeClass('current');
    $categoryFilter.find('a').removeClass('active current');
    $(this).parent('li').addClass('current');
    $(this).addClass('active current');
    $target = $(this).data('target');
    filterImages($(this), '.brand-menu');
    $(window).scrollTop(0, 2000);
});

