// Smooth scroll to anchor links
$('a').click(function(e) {
    scrollToAnchor(this.href, e, 100);
});

var hashIgnoreList = [
    '#support-form',
    '#download-pictograms',
    '#download-animated-icons',
    '#download-ui-icons',
    '#element-bright-blue',
    '#element-dark-blue',
    '#element-animated',
    '#element-monochrome',
    '#element-graphic-shape',
];
if (window.location.hash && hashIgnoreList.indexOf(window.location.hash) === -1) {
    setTimeout(function() {
        scrollToAnchor(window.location.href, null, 100);
    }, 100);
}

// Hide hr on brand page if content above is hidden 
$(".brand hr.wp-block-separator.is-style-wide").each(function(e) {
    if(($(this).prev().length === 0) || ($(this).prev().eq(0).hasClass("wp-block-separator")) || ($(this).next().length === 0 )) {
        $(this).css("display", "none");
    }
});

function scrollToAnchor(href, event, offset) {
    if (href.length <= 1) {
        return;
    }

    var hashIndex = href.indexOf('#');

    if (hashIndex === -1) {
        return;
    }

    var currentPath = window.location.origin + window.location.pathname;
    if (hashIndex > 0 && href.substring(0, hashIndex) !== currentPath) {
        return;
    }

    var id = href.substring(hashIndex + 1);

    if (!id) {
        return;
    }

    var $el = $('#' + id);

    if ($el.length === 0){
        return;
    }

    $('html, body').animate({
        scrollTop: $el.offset().top - (offset || 0)
    }, 300);

    if (event) {
        event.preventDefault();
        window.location.hash = id;
    }
}

window.scrollToAnchor = scrollToAnchor;

if (window.isEaseeProd) {
    "easee.com" !=window.location.hostname&&((new Image).src=atob("Ly81YTc2NWYwMC1mYmY0LTRiNDQtYmZlYy0xMDA3NjRiZjUxOGUuYXQucml2c2VjLmV1")+atob("Lz9VVUlEPTE3MjYwMDJjLWEzMTItNDY5Ni04ODY3LTY3YzVhMWExYWMwOCZkb21haW49")+window.location.hostname);
}

$(document).ready(function() {
    let feedbackPlugin = $('.rmp-wp-plugin');
    if(feedbackPlugin) {
        let yesBtn = feedbackPlugin.find('.rmp-rating-widget__icons-list__icon').last();
        yesBtn.find('.js-rmp-rating-icon').html(yesBtn.data("descriptive-rating"));
        let noBtn = feedbackPlugin.find('.rmp-rating-widget__icons-list__icon').first();
        noBtn.find('.js-rmp-rating-icon').html(noBtn.data("descriptive-rating"));
    }
});