var hideCookieBannerKey = 'hideCookieBanner';

$('#cookie-banner-close').click(function(e) {
    e.preventDefault();

    $('#cookie-banner').removeClass('show');

    var expires = new Date();
    expires.setMonth(expires.getMonth() + 12);
    document.cookie = hideCookieBannerKey + '=1; expires=' + expires + '; path=/';
});

if (getCookie(hideCookieBannerKey) !== '1' && window.location.pathname !== '/') {
    var $cookieBanner = $('#cookie-banner');
    setTimeout(function() {
        $cookieBanner.addClass('show');
    }, 500);
}

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
}