import Shuffle from 'shufflejs';

var $countryOptions = $('.integrations-partners .countries .country-options');
var $activeCountry = $('.integrations-partners .countries .active');
var $typeOptions = $('.integrations-partners .service-types .type-options');
var $activeType = $('.integrations-partners .service-types .active');
var $partners = $('.integrations-partners .partners-display .partner-item');
var $selectedFilters = [];
var selectedTags = ['', '', ''];

'use strict';

var element = document.querySelector('.partners-display');

if (element) {
    var shuffleInstance = new Shuffle(element, {
        itemSelector: '.partner-item',
        columnThreshold: 1,
        filterMode: Shuffle.FilterMode.ALL,
    });

    // Listener to reset styles on resize
    window.addEventListener('resize', function() {
        shuffleInstance.update();
        shuffleInstance.layout();
    
        $('.integrations-partners .partners-display .partner-item .image-wrapper').each(function (e) {
            if($(this).parents(".partner-item").hasClass("expanded")) {
                $(this).css({
                    'width': '100%'
                });
            } else {
                $(this).css({
                    'width': '100%',
                    'min-width': 'initial'
                });
            }
            if ($(window).width() < 1000) {
                $(this).css({
                    'height':'initial'
                });
                $(this).parents('.partner-item').css({
                    'min-height': 'initial',
                    'max-height': 'initial'
                });
            } else {
                $(this).parents('.partner-item').css({
                    'min-height': '480px',
                    'max-height': '480px'
                });
                $(this).css({
                    'height':'362px'
                });
                if($(this).parents(".partner-item").hasClass("expanded")) {
                    condenseResize($(this))
                }
            }
        })
    });
}

function condenseResize($this) {
    $this.parents('.partner-item').css({
        'min-height': '480px',
        'max-height': '480px'
    });
    $this.css({
        'min-width': $this.parents(".partner-item").next().length === 0 ? $this.parents(".partner-item").prev().find(".image-wrapper").outerWidth() : $this.parents(".partner-item").next().find(".image-wrapper").outerWidth()
    });
    $this.children('.wrapper').css({
        'min-width': 'initial'
    });
    $this.css({
        width: '100%'
    });
    $this.children('.partner-info').css({
        'visibility': 'hidden',
        'opacity': 0,
        'position': 'absolute'
    });
    $this.parents('.partner-item').removeClass('expanded');
    shuffleInstance.layout();
    shuffleInstance.update();
}

function sortByIndex(element) {
    return element.getAttribute('data-index');
}
var options;
options = {
    by: sortByIndex,
};

function filterImages($this, $class) {
    $this.parents($class).addClass("options-active");
    var $countryActive = $('.integrations-partners  .filters .countries.options-active');
    var $typeActive = $('.integrations-partners .filters .service-types.options-active');

    if ($countryActive.length > 0 && $countryActive.find('li.selected a').data('slug') != 'all') {
        var $activeTag = $countryActive.find('li.selected a').data('slug');
        $selectedFilters.push($activeTag);

        for (var i = 0; i < $selectedFilters.length; i++) {
            if (Array.isArray($selectedFilters[i])) {
                for (var j = 0; j < $selectedFilters[i].length; j++) {
                    selectedTags[0] = $selectedFilters[i][j];
                }
            }
            else {
                selectedTags[0] = $selectedFilters[i];
            }
        }
    }
    else {
        selectedTags[0] = '';
    }

    if ($typeActive.length > 0 && $typeActive.find('li.selected a').data('slug') != 'all') {
        var $activeTag = $typeActive.find('li.selected a').data('slug');
        $selectedFilters.push($activeTag);

        for (var i = 0; i < $selectedFilters.length; i++) {
            if (Array.isArray($selectedFilters[i])) {
                for (var j = 0; j < $selectedFilters[i].length; j++) {
                    selectedTags[1] = $selectedFilters[i][j];
                }
            }
            else {
                selectedTags[1] = $selectedFilters[i];
            }
        }
    }
    else {
        selectedTags[1] = '';
    }
    shuffleFilter();
}

function shuffleFilter() {
    shuffleInstance.filter(function (element, shuffle) {
        var groups = element.getAttribute('data-groups');
        var lastGroups = JSON.parse(element.getAttribute('data-groups'));
        var checkArray = [];
        for (var i = 0; i < selectedTags.length; i++) {
            if (i == 2) {
                var elementTitle = lastGroups[lastGroups.length - 1];
                if (elementTitle.indexOf(selectedTags[2]) !== -1) {
                    checkArray.push(true);
                }
                else {
                    checkArray.push(false);
                }
            }
            else {
                if (groups.indexOf(selectedTags[i]) !== -1) {
                    checkArray.push(true);
                }
                else {
                    checkArray.push(false);
                }
            }
        }
        if (checkArray.indexOf(false) == -1) {
            return true;
        }
        else {
            return false;
        }
    })

    $('.partner-item.shuffle-item--visible').each(function (index) {
        $(this).attr('data-index', index + 1);
    })
    shuffleInstance.sort(options);
}

$('#partner-search').keyup(function (evt) {
    var searchText = evt.target.value.toLowerCase();
    selectedTags[2] = searchText;
    shuffleFilter();
});

$activeType.find('a').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    $typeOptions.slideToggle('fast');
    $this.toggleClass('open');
    $this.parent().toggleClass('options-open');
});

$typeOptions.find('a').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    $activeType.find('span').html($this.html());
    $typeOptions.css({
        'display': 'none'
    });
    $typeOptions.children('li.selected').removeClass('selected');
    $this.parent('li').addClass('selected');
    $this.parents('.service-types').find('.active').children('a').removeClass('open');
    $this.parents('.service-types').find('.active').removeClass('options-open');
    filterImages($(this), '.service-types');
});

$activeCountry.find('a').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    $countryOptions.slideToggle('fast');
    $this.toggleClass('open');
    $this.parent().toggleClass('options-open');
});

$countryOptions.find('a').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    $activeCountry.find('span').html($this.html());
    $countryOptions.css({
        'display': 'none'
    });
    $countryOptions.children('li.selected').removeClass('selected');
    $this.parent('li').addClass('selected');
    $this.parents('.countries').find('.active').children('a').removeClass('open');
    $this.parents('.countries').find('.active').removeClass('options-open');
    filterImages($(this), '.countries');
});

if ($(window).width() >= 1200) {
    var isOpening = false;
    $('.integrations-partners .partners-display .partner-item .image-wrapper').hover(function (e) {
        if (!$(this).parents('.partner-item').hasClass('expanded')) {
            var $this = $(this);
            var $images = $this.parents('.partners-display').children('.partner-item').find('.image-wrapper');
            var $texts = $this.parents('.partners-display').children('.partner-item').find('.partner-bottom');
            var $currentImage = $this;
            var $currentText = $this.parents('.partner-item').find('.partner-bottom')

            $images.addClass('fade-down');
            $texts.addClass('fade-down');
            $currentImage.removeClass('fade-down');
            $currentText.removeClass('fade-down');
            $currentImage.addClass('hover');

            isOpening = true;
        }
    }, function () {
        var $this = $(this);
        var $images = $this.parents('.partners-display').children('.partner-item').find('.image-wrapper');
        var $texts = $this.parents('.partners-display').children('.partner-item').find('.partner-bottom');
        var $currentImage = $this;

        $images.removeClass('fade-down');
        $texts.removeClass('fade-down');
        $currentImage.removeClass('hover');

        isOpening = false;

    });
}

$partners.find('.image-wrapper').each(function (index) {
    if ($(window).width() >= 1000) {
        var $this = $(this);
        setTimeout(function () {
            $this.css({
                'height': $this.outerHeight(),
                'min-width': $this.outerWidth()
            });
        }, 10);
    } 
});

var partnerItemLocked = false;
function unlockPartnerItem() {
    partnerItemLocked = false;
}
$partners.find('.image-wrapper').click(function (e) {
    if (!partnerItemLocked) {
        partnerItemLocked = true;
        setTimeout(unlockPartnerItem, 700);

        var $target = $(e.target);
        if (!$target.is('a')) {
            e.preventDefault();
            var $expandedPartner = $('.integrations-partners .partners-display .partner-item.expanded');
            if ($expandedPartner.length > 0) {
                condensePartner($expandedPartner.find('.image-wrapper'));
            }
            var $this = $(this);
            if ($this.parents('.partner-item').hasClass('expanded')) {
                condensePartner($this);
            }
            else {
                expandPartner($this);
            }
        }
    }
}); 

function expandPartner($this) {
    var $itemWidth = $this.outerWidth();
    $this.parents('.partner-item').addClass('expanded');              
    if ($(window).width() < 1000) {
        $this.children('.partner-info').slideDown(300);
        setTimeout(function () {
            shuffleInstance.layout();
        }, 500);
        setTimeout(function () {
            $this.children('.partner-info').css({
                'visibility': 'visible',
                'opacity': 1,
                'position': 'static',
                'display': 'block'
            });
        }, 400);
    }
    else {
        $this.parents('.partner-item').css({
            'min-height': '380px',
            'max-height': '380px'
        });
        $this.children('.wrapper').css({
            'min-width': $itemWidth
        });
        $this.css({
            width: '29.35%'
        });
        setTimeout(function () {
            $this.animate({
                width: '100%'
            });
        }, 100);
        setTimeout(function () {
            $this.children('.partner-info').css({
                'visibility': 'visible',
                'opacity': 1,
                'position': 'static',
                'display': 'block'
            });
        }, 500);
        var elementIndex = $this.parent().attr('data-index');
        var $sibling1 = $(`.partner-item.shuffle-item--visible[data-index='${elementIndex - 1}']`);
        var $sibling2 = $(`.partner-item.shuffle-item--visible[data-index='${elementIndex - 2}']`);
        if ((elementIndex + 1) % 3 == 0) {
            $this.parent().attr('data-index', (elementIndex - 1));
            $sibling1.attr('data-index', elementIndex);
        }
        else if (elementIndex % 3 == 0) {
            $this.parent().attr('data-index', (elementIndex - 2));
            $sibling1.attr('data-index', elementIndex);
            $sibling2.attr('data-index', elementIndex - 1);
        }
        shuffleInstance.sort(options);
    }
}

function condensePartner($this) {
    if ($(window).width() < 1000) {
        $this.children('.partner-info').slideUp(300);
        $this.children('.partner-info').css({
            'opacity': 0,
            'display': 'block'
        });
        setTimeout(function () {
            shuffleInstance.layout();
        }, 500);
        setTimeout(function () {
            $this.parents('.partner-item').removeClass('expanded');
        }, 500);
    }
    else {
        $this.parents('.partner-item').css({
            'min-height': '480px',
            'max-height': '480px'
        });
        $this.css({
            'min-width': $this.parents(".partner-item").next().length === 0 ? $this.parents(".partner-item").prev().find(".image-wrapper").outerWidth() : $this.parents(".partner-item").next().find(".image-wrapper").outerWidth()
        });
        $this.animate({
            width: '29.35%',
        });
        setTimeout(function () {
            $this.children('.wrapper').css({
                'min-width': 'initial'
            });
        }, 500);
        setTimeout(function () {
            $this.css({
                width: '100%'
            });
        }, 500);
        $this.children('.partner-info').css({
            'visibility': 'hidden',
            'opacity': 0,
            'position': 'absolute'
        });
        setTimeout(function () {
            $this.parents('.partner-item').removeClass('expanded');
        }, 500);
        setTimeout(function () {
            shuffleInstance.layout();
            shuffleInstance.update();
        }, 600);
    }
}

