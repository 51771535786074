const $video = $(".block-stories .video");
const $videoShow = $(".block-stories .outer-max-width");
const $videoTop = $(".block-stories .videoTop");
const $videoTitle = $(".block-stories .title-container");
const $videoOverlay = $(".block-stories .pause_overlay");
const $mediaContainers = $(".block-stories .media-container");
const $mediaTitles = $(".block-stories .media-container .title-container .title");
const $extraH2 = $(".block-stories .title-container h2:nth-child(2)");
const $storiesBannerImg = $(".block-stories .media-container.active");

$extraH2.remove();

// Optimise image size for mobile
if($mediaContainers.length > 0) {
    const $desktopImageUrl = $storiesBannerImg.data("image");
    const $mobileImageUrl = $storiesBannerImg.data("mobileimage");

    $(document).ready(function() {
        if($( window ).width() <= 600) {
            $storiesBannerImg.css("background-image", "url(" + $mobileImageUrl + ")");
        } else {
            $storiesBannerImg.css("background-image", "url(" + $desktopImageUrl + ")");
        }     
    });

    $(window).resize(function(){
        if($( window ).width() <= 600) {
            $storiesBannerImg.css("background-image", "url(" + $mobileImageUrl + ")");
        } else {
            $storiesBannerImg.css("background-image", "url(" + $desktopImageUrl + ")");
        }  
    });
}

if($video.length !== 0) {
    // Pause video with click on pause button
    $videoShow.click(function(e) {
        $video.get(0).pause();
        $videoOverlay.css("display", "block");
        $videoTop.css("display", "block");
        $videoTitle.css("display", "block");
    });

    // Play video with click on play button
    $(".block-stories .playButton").click(function(e) {
        e.preventDefault();
        $videoShow.css("display", "block");
        $video.get(0).play();
        $videoTop.css("display", "none");
        $videoTitle.css("display", "none");
        $videoOverlay.css("display", "none");
    });

    // Hide overlay and text when video is playing
    document.querySelector('.video').addEventListener('play',function(e) {
        $videoTop.css("display", "none");
        $videoTitle.css("display", "none");
        $videoOverlay.css("display", "none");
    },false);

    // Hide overlay and show text when video had ended
    document.querySelector('.video').addEventListener('ended',function(e) {
        $videoShow.css("display", "none");
        $videoTitle.css("display", "block");
        $videoTop.css("display", "block");
        $videoOverlay.css("display", "none");
    },false);

    // Show overlay and text when video is paused
    document.querySelector('.video').addEventListener('pause',function(e) {
        $videoOverlay.css("display", "block");
        $videoTop.css("display", "block");
    },false);
}
