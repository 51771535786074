import Glide from '@glidejs/glide'

function initKenBurns(id, playSpeed) {
    var kenBurnsGlide = new Glide('#' + id, {
        type: 'slider',
        startAt: 0,
        gap: 0,
        autoplay: parseInt(playSpeed, 10),
        hoverpause: false,
        rewind: true,
        rewindDuration: 0,
        animationDuration: 0,
    })
    kenBurnsGlide.mount();

    // Adjust animation speed if slideshow speed is faster/slower
    if(playSpeed > 3500) {
        const $slideShowImg = $(".slideshow-ken-burns .glide__slide img");
        $slideShowImg.each(function() { $(this).addClass("slideshow-extraSlow"); });
    } else if(playSpeed > 3000) {
        const $slideShowImg = $(".slideshow-ken-burns .glide__slide img");
        $slideShowImg.each(function() { $(this).addClass("slideshow-slow"); });
    }

    // Toggle slideshow
    $('.slider-playback').on('click', function (e) {
        e.preventDefault();
        const $playIcon = $(".playIcon");
        const $pauseIcon = $(".pauseIcon");
        const $slide = $(".ken-burns-slideshow .glide__slide--active img");
    
        if($(this).hasClass('play')) {
            // Pause slideshow
            $(this).removeClass('play').addClass('pause');
            $playIcon.addClass("show");
            $pauseIcon.removeClass("show");
            $slide.css("animation-play-state", "paused");

            kenBurnsGlide.pause(); 
        } else if ($(this).hasClass('pause')) {
            // Play slideshow
            $(this).removeClass('pause').addClass('play');
            $pauseIcon.addClass("show");
            $playIcon.removeClass("show");
            $slide.css("animation-play-state", "paused");

            kenBurnsGlide.play().go(">")
        }
    }); 
}

window.initKenBurns = initKenBurns;
