import {intersectionObserve} from "./_intersection-observer";
import {brandIntersectionObserve} from "./_brand-intersection-observer";

const defaultContent = document.querySelectorAll('.default-content');
const allAnchors = document.querySelectorAll('.aside-item');
const brandHero = document.querySelectorAll('.brand-hero');
const brandMenu = document.querySelector('.brand-menu');
const brandNavigation = document.getElementById('brand-page-navigation');

brandIntersectionObserve(defaultContent, 0, (element, intersectionRatio) => {
    makeActive(element, intersectionRatio);
});

if (brandHero.length > 0) {
    intersectionObserve(brandHero, 0, (element, intersectionRatio) => {
        if (intersectionRatio <= 0) {
            brandMenu.classList.add('sticky');
        } else {
            brandMenu.classList.remove('sticky');
        }
    });

    if (brandNavigation) {
        // Make sure the sidebar menu stops when it gets to the bottom
        window.addEventListener('scroll', function (e) {
            var verticalMargin = 6 * 16; // 6 rem

            if (window.scrollY > window.innerHeight * 2) {
                if (brandNavigation.getBoundingClientRect().y - brandMenu.getBoundingClientRect().height - verticalMargin * 2 < 0) {
                    brandMenu.classList.remove('sticky');
                    brandMenu.classList.add('bottom');
                } else {
                    brandMenu.classList.add('sticky');
                    brandMenu.classList.remove('bottom');
                }
            }
        });
    }
}
else {
    if(brandMenu != null) {
        brandMenu.classList.add('sticky');
    }

    if (brandNavigation) {
        // Make sure the sidebar menu stops when it gets to the bottom
        var brandMenuMarginY = brandMenu.getBoundingClientRect().y
        window.addEventListener('scroll', function (e) {
            var verticalMargin = 6 * 16; // 6 rem
                if (brandNavigation.getBoundingClientRect().y - brandMenu.getBoundingClientRect().height - verticalMargin - brandMenuMarginY < 0) {
                    brandMenu.classList.remove('sticky');
                    brandMenu.classList.add('bottom');
                } else {
                    brandMenu.classList.add('sticky');
                    brandMenu.classList.remove('bottom');
                }
        });
    }
}

function checkSiblings(anchor, nextSibling) {
    //TODO:check and toggle all the siblings here instead of in makeactive
    if (!nextSibling) {
        anchor.addClass('active')
    }
}

//this kinda works for now, bug with too fast scrolling for removing active of level_1
function makeActive(element, intersectionRatio) {
    const $url = window.location.href;

    let anchor = $(`[data-target="${element.id}"]`);
    let anchorData = anchor.attr('data-target')
    let nextSibling = anchor.parent().next('li').find('.aside-item')[0];
    let prevSibling = anchor.parent().prev('li').find('.aside-item')[0];
    if (intersectionRatio > 0 && anchorData == element.id) {
        if (!nextSibling) {
            anchor.addClass('active');
            return;
        } else if (nextSibling) {
            if (nextSibling.classList.contains('active') && nextSibling.classList.contains('level_2')) {
                if($url.includes("templates") === true) {
                    anchor.addClass('active');
                    return;
                } else {
                    anchor.removeClass('active');
                    return;
                }
            } else {
                anchor.addClass('active')
            }
        } else if (anchor[0].classList.contains('level_2')) {
            prevSibling.classList.remove('active')
            anchor.addClass('active');
            return;
        } else {
            anchor.addClass('active');
        }
        return;
    } else if (intersectionRatio <= 0) {
        anchor.removeClass('active');

        return;
    }
    anchor.removeClass('active')
}

if (defaultContent.length > 0 && brandMenu) {
    defaultContent.forEach(
        function setAnchor(key, value) {
            let currentContent = defaultContent[value];
            let currentAnchor = allAnchors[value]
            currentAnchor.classList.add(currentContent.attributes[0].value)
        }
    );
}

var $anchors = $('a.aside-item');
for(var i=0; i<$anchors.length; i++) {
    if($anchors.eq(i).hasClass('level_2')) {
        $anchors.eq(i).parent().addClass('level_2');
        var $anchorText = $anchors.eq(i).parent().prevAll('li.level_1').first().children('a.aside-item').data('target');
        $anchors.eq(i).parent().addClass($anchorText);
    }
    else {
        $anchors.eq(i).parent().addClass('level_1');
    }
}