var $typeTesterOptions = $('.brand-type-tester .options');
var $typeTesterEditor = $('.brand-type-tester .type-tester-editor');

$typeTesterOptions.find('select.type-tester-weight').change(function(e) {
    $typeTesterEditor.css('font-weight', e.target.value);
    var name = $(e.target).find('option:selected').text();
    $(this).siblings('span').html(name);
});

$typeTesterOptions.find('select.type-tester-size').change(function(e) {
    $typeTesterEditor.css('font-size', e.target.value);
    $(this).siblings('span').html(e.target.value);
});
