const proptlyFormWrapper = document.querySelector('.proptly-form');
const proptlyForm = proptlyFormWrapper?.querySelector('form');
const returnUrl = proptlyForm?.querySelector('input.return-url')?.value;
const errorDiv = proptlyForm?.querySelector('.form-error');

proptlyForm?.addEventListener('submit', submitProptlyForm);

function submitProptlyForm(e) {
    e.preventDefault();

    let data = new FormData(proptlyForm);
    data.append('action', 'proptly_submit');

    errorDiv.classList.remove('show');

    $.ajax({
        url: easee_scripts.ajax_url,
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        error: function (data) {
            const json = JSON.parse(data.responseText);
            errorDiv.innerHTML = json?.error ?? 'An unknown error occured.';
            errorDiv.classList.add('show');
        },
        success: function (data) {
            window.location = returnUrl;
        }
    });
}
