import bodymovin from 'lottie-web'
import { intersectionObserve } from "./_intersection-observer";

let index = 0;

function setupAnimatedIcons() {
    const icons = document.querySelectorAll('.icon-animation');
    intersectionObserve(icons, 0, (element, intersectionRatio) => {
        loadAnimation(element, intersectionRatio);
    });
}

function loadAnimation(element, intersectionRatio) {
    const iconEl = element.getElementsByClassName('icon-animation-test')[0];
    const pathing = element.getAttribute('data-anim');

    if (intersectionRatio > 0 && element.classList.contains('loaded')) {
        return;
    } else if (intersectionRatio <= 0) {
        iconEl.style.display = 'block';
        element.classList.remove('loaded');

        // Destroy existing animations
        const idx = element.getAttribute('data-icon-index');
        bodymovin.destroy('icon-animation-test-' + idx);
        element.removeAttribute('data-icon-index');

        return;
    }

    element.classList.add('loaded');

    if (element.getAttribute('data-icon-index') === null) {
        element.setAttribute('data-icon-index', index);
        createIconAnimation(iconEl, index,pathing);
        index++;
    }
}

const createIconAnimation = function(element, index,pathing) {
    return bodymovin.loadAnimation({
        container: element, // Required
        path: '/wp-content/themes/grensesnitt/animations/'+pathing+'.json', // Required
        renderer: 'svg', // Required
        loop: false, // Optional
        autoplay: true, // Optional
        name: "icon-animation-test-" + index, // Name for future reference. Optional.
    });
};

setupAnimatedIcons();
window.setupAnimatedIcons = setupAnimatedIcons;