var $retailerSearchHeading = $('.find-retailer h4');
var $retailerSearchInput = $('.retailer-search input');
var $retailers = $('.block-support .search-results li');
var $result = $('.block-support .result');
var $searchResults = $('.block-support .search-results');
var $clearInputLink = $('.block-support .clear-input');
var $triedEverything = $('.block-support .tried-everything');
var $unknownRetailer = $('.block-support .unknown-retailer');
var $triedRetailer = $('.block-support .tried-retailer');
var $helpLinks = $('.block-support .help-links');

$retailerSearchInput.focus(function(e) {
    filterRetailers(e.target);
    $retailerSearchHeading.addClass('focus');
});

$retailerSearchInput.blur(function(e) {
    $retailerSearchHeading.removeClass('focus');
});

$retailerSearchInput.keyup(function(e) {
    if ($(this).val() || $result.hasClass('show')) {
        $clearInputLink.show();
    } else {
        $clearInputLink.hide();
    }

    filterRetailers(e.target);

    /*switch (e.keyCode) {
        case 40: // arrow down
            break;
        case 38: // arrow up
            break;
    }*/
});

$('.find-retailer form').submit(function(e) {
    e.preventDefault();
});

$clearInputLink.click(function(e) {
    e.preventDefault();
    var $this = $(this);

    $this.siblings('input').val('');
    $this.hide();
    $result.removeClass('show');
    $helpLinks.show();

    $unknownRetailer.show();
    $triedEverything.hide();
    $triedRetailer.show();

    filterRetailers($retailerSearchInput);
});

function filterRetailers(target) {
    var $this = $(target);

    var searchString = $this.val().toLowerCase();
    $retailers.addClass('hide');

    if (searchString && searchString.length > 0) {
        var numResults = 0;
        $retailers.each(function () {
            var $retailer = $(this);
            var name = $retailer.data('name') || '';
            if (name.toLowerCase().indexOf(searchString) !== -1) {
                $retailer.removeClass('hide');
                numResults++;
            }
        });

        if (numResults === 0) {
            $searchResults.find('li.no-results').removeClass('hide');
        }

        $searchResults.addClass('show');
    } else {
        $searchResults.removeClass('show');
    }
}

$retailers.find('a').click(function(e) {
    e.preventDefault();
    var $li = $(this).parents('li');
    var $account = $li.find('.account');

    var $resultAccount = $result.find('.account');
    $resultAccount.html($account.html());
    $resultAccount.show();
    $retailers.addClass('hide');
    $searchResults.removeClass('show');
    $result.addClass('show');

    $helpLinks.hide();
    $triedEverything.hide();
    $triedRetailer.show();
});

$triedRetailer.click(function(e) {
    e.preventDefault();

    $helpLinks.show();
    $unknownRetailer.hide();
    $triedEverything.show();
    $triedRetailer.hide();
});

$('.block-support .show-support-form').click(function(e) {
    e.preventDefault();
   showSupportForm();
});

function showSupportForm() {
    var $support = $('.block-support');

    $support.find('.guide').hide();
    $support.find('.support-form-wrapper').show();
}

$('.block-support .close-support-form').click(function(e) {
    e.preventDefault();
    var $support = $(this).parents('.block-support');

    $support.find('.guide').show();
    $support.find('.support-form-wrapper').hide();
});

if (window.location.hash === '#support-form') {
    showSupportForm();
    window.scrollToAnchor(window.location.hash, null, 100);
}
