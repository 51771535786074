function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    return "unknown";
}

// Open App Store or Google Play if a user is visiting https://easee-international.com/app/ from
// and iOS or Android device
switch (window.location.pathname) {
    case '/app/':
        switch (getMobileOperatingSystem()) {
            case 'Android':
                window.location.href = "https://play.google.com/store/apps/details?id=no.easee.apps.easee.users&hl=en&gl=US";
                break;
            case "iOS":
                window.location.href = "https://apps.apple.com/gb/app/easee/id1488907960";
                break;
            default:
                break;
        }
        break;
    case '/installer-app/':
    case '/no/installatoer-app/':
        switch (getMobileOperatingSystem()) {
            case 'Android':
                window.location.href = "https://play.google.com/store/apps/details?id=no.easee.apps.installers&hl=en&gl=US";
                break;
            case "iOS":
                window.location.href = "https://apps.apple.com/no/app/easee-installers/id1535955719?l=nb";
                break;
            default:
                break;
        }
        break;
}
