var formDiv = $('.modal-form')

$('a[href="#transparency"]').click(function(e) {
    e.preventDefault();
    window.history.pushState({}, '', e.target.getAttribute('href'));

    showModal('transparency-modal-form');
    hideModal('become-retailer-form');
    hideModal('become-customer-form');
    hideModal('contact-modal-form');
    hideModal('order-modal-form');
    hideModal('quote-modal-form');
});

$('a[href="#become-retailer"]').click(function(e) {
    e.preventDefault();
    window.history.pushState({}, '', e.target.getAttribute('href'));

    hideModal('transparancy-form');
    showModal('become-retailer-form');
    hideModal('become-customer-form');
    hideModal('contact-modal-form');
    hideModal('order-modal-form');
    hideModal('quote-modal-form');
});

$('a[href="#contact"]').click(function(e) {
    e.preventDefault();
    window.history.pushState({}, '', e.target.getAttribute('href'));

    hideModal('transparancy-form');
    hideModal('become-retailer-form');
    hideModal('become-customer-form');
    showModal('contact-modal-form');
    hideModal('order-modal-form');
    hideModal('quote-modal-form');
});

$('a[href="#order"]').click(function(e) {
    e.preventDefault();
    window.history.pushState({}, '', e.target.getAttribute('href'));

    hideModal('transparancy-form');
    hideModal('become-retailer-form');
    hideModal('become-customer-form');
    hideModal('contact-modal-form');
    showModal('order-modal-form');
    hideModal('quote-modal-form');
});

$('a[href="#quote"]').click(function(e) {
    e.preventDefault();
    window.history.pushState({}, '', e.target.getAttribute('href'));

    hideModal('transparancy-form');
    hideModal('become-retailer-form');
    hideModal('become-customer-form');
    hideModal('become-customer-form');
    hideModal('contact-modal-form');
    hideModal('order-modal-form');
    showModal('quote-modal-form');
});

$('a[href="#become-customer"]').click(function(e) {
    e.preventDefault();
    window.history.pushState({}, '', e.target.getAttribute('href'));

    hideModal('transparancy-form');
    hideModal('become-retailer-form');
    showModal('become-customer-form');
    hideModal('contact-modal-form');
    hideModal('order-modal-form');
    hideModal('quote-modal-form');
});

if (window.location.hash === '#become-retailer') {
    showModal('become-retailer-form');
} else if (window.location.hash === '#become-customer') {
    showModal('become-customer-form');
} else if(window.location.hash === "#contact"){
    showModal('contact-modal-form')
} else if(window.location.hash === "#order") {
    showModal('order-modal-form')
} else if(window.location.hash === "#quote") {
    showModal('quote-modal-form')
} else if(window.location.hash === "#transparancy") {
    showModal('quote-modal-form')
}

formDiv.find('a.close-modal').click(function(e) {
    e.preventDefault();

    hideModal();
});

formDiv.click(function(e) {
    if ($(e.target).hasClass('modal-form')) {
        hideModal();
    }

});

var $becomeRetailerForm = $('#become-retailer-form');
var $buyFromWholesaler = $becomeRetailerForm.find('.buy-from-wholesaler');
var $wholesalerSection = $becomeRetailerForm.find('.wholesaler-name');
$buyFromWholesaler.change(function(e) {
    var isChecked = $(this).prop('checked');

    if (isChecked) {
        $wholesalerSection.show();
        $wholesalerSection.find('input').attr('required', 'required');
    } else {
        $wholesalerSection.hide();
        $wholesalerSection.find('input').attr('required', false);
    }
});

var $becomeRetailerFormStatic = $('#become-retailer-form-static');
var $buyFromWholesalerStatic = $becomeRetailerFormStatic.find('.buy-from-wholesaler');
var $wholesalerSectionStatic = $becomeRetailerFormStatic.find('.wholesaler-name');
$buyFromWholesalerStatic.change(function(e) {
    var isChecked = $(this).prop('checked');

    if (isChecked) {
        $wholesalerSectionStatic.show();
        $wholesalerSectionStatic.find('input').attr('required', 'required');
    } else {
        $wholesalerSectionStatic.hide();
        $wholesalerSectionStatic.find('input').attr('required', false);
    }
});

var $easeePayForm = $('#easeepay-form');
var $alreadyInstalled = $easeePayForm.find('.already-installed');
var $alreadyInstalledNo = $easeePayForm.find('.already-installed-no');
var $facilityKey = $easeePayForm.find('.facility-key');
$alreadyInstalled.change(function(e) {
    var isChecked = $(this).prop('checked');

    if (isChecked) {
        $facilityKey.show();
        //$facilityKey.find('input').attr('required', 'required');
    } else {
        $facilityKey.hide();
        //$facilityKey.find('input').attr('required', false);
    }
});
$alreadyInstalledNo.change(function(e) {
    var isChecked = $(this).prop('checked');

    if (isChecked) {
        $facilityKey.hide();
        //$facilityKey.find('input').attr('required', false);
    } 
});

var $becomeRetailerFormStatic = $('#become-retailer-form-static');
var $buyFromWholesalerStatic = $becomeRetailerFormStatic.find('.buy-from-wholesaler');
var $wholesalerSectionStatic = $becomeRetailerFormStatic.find('.wholesaler-name');
$buyFromWholesalerStatic.change(function(e) {
    var isChecked = $(this).prop('checked');

    if (isChecked) {
        $wholesalerSectionStatic.show();
        $wholesalerSectionStatic.find('input').attr('required', 'required');
    } else {
        $wholesalerSectionStatic.hide();
        $wholesalerSectionStatic.find('input').attr('required', false);
    }
})

function showModal(id) {
    var formDiv = $('#'+id)
    formDiv.addClass('show');

    setTimeout(function() {
        formDiv.addClass('fade-in');
    }, 100);
}

function hideModal(id) {
    var div = formDiv;

    if (id) {
        div = $('#' + id);
    }

    div.removeClass('fade-in');

    setTimeout(function() {
        div.removeClass('show');
    }, 300);
}
