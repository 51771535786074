$('.news-archive .filter .current-item').click(function(e) {
    e.preventDefault();

    const $this = $(this);
    $this.parent().toggleClass('open');
});

var $newsArchive = $('.news-archive');
var $primary = $('.news-archive .primary');
var $filter = $('.news-archive .filter');

if ($('.news-archive').length > 0) {

    var filterTop = $filter.offset().top;

    $(window).scroll(function(e) {
        var scrollTop = $(this).scrollTop();
        var primaryHeight = $primary.height();
        var marginTop = $newsArchive.css('marginTop').replace('px', '');

        if (scrollTop > filterTop - 64) {
            $filter.addClass('sticky');
        } else {
            $filter.removeClass('sticky');
        }

        if (scrollTop > primaryHeight) {
            $filter.css('transform', 'translateY(-' + (scrollTop - primaryHeight - marginTop / 2) + 'px)');
        }
    });
}