$('.brand-downloads-image-text a.image-link').hover(function() {
    var $overlay = $(this).find('.overlay');
    $overlay.css('background', 'rgba(0, 0, 0, 0.8)');
    $(this).find('.overlay svg').css({
        'opacity': 1
    });
}, function() {
    var $overlay = $(this).find('.overlay');
    $overlay.css('background', 'rgba(0, 0, 0, 0)');
    $(this).find('.overlay svg').css({
        'opacity': 0
    });
})