const toggler = document.querySelector(".color-picker .triggerPerspective");
const togglerExists = document.querySelector(".color-picker");
const title = document.querySelector(".color-picker .title");

// Toggle side/front view
if((togglerExists?.length !== 0) && (togglerExists !== null)) {
    toggler.addEventListener("click", function(){
        let side = document.querySelectorAll(".color-picker .slidesSide");
        let front = document.querySelectorAll(".color-picker  .slidesFront");

        front.forEach((element, i) => element.classList.contains("show") ? element.classList.remove("show") : element.classList.add("show"));
        side.forEach((element, i) => element.classList.contains("show") ? element.classList.remove("show") : element.classList.add("show"));
    });
}
