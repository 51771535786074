const drawerDisplay = document.querySelectorAll(".drawer-with-video-image .drawerDisplay");
const drawers = document.querySelectorAll(".drawer-with-video-image .drawer-item.drawer-pluss-icon");
const close_buttons = document.querySelectorAll(".drawer-with-video-image .close_drawer");

function openDrawer(i, drawer, e) {
    e.preventDefault();
    document.body.classList.add("no-vertical-scroll");
    drawerDisplay[i].style.height = "100vh";
    drawerDisplay[i].style.opacity = "1";
    drawerDisplay[i].children[1].style.transform = "translateX(0%)";
}

if(drawers.length !== 0) {
    for(let i = 0; i < drawers.length; i++) {
        drawers[i].addEventListener("click", openDrawer.bind(null,i,drawers[i]));
        close_buttons[i].addEventListener("click", function(){
            document.body.classList.remove("no-vertical-scroll");
            if($(close_buttons[i]).parents("div.drawerDisplay").find("svg.pauseIcon").hasClass("show")) {
                var video = $(close_buttons[i]).parents("div.drawerDisplay").find("video");
                var $playIcon = $(close_buttons[i]).parents("div.drawerDisplay").find("path.playIcon");
                var $pauseIcon = $(close_buttons[i]).parents("div.drawerDisplay").find("svg.pauseIcon");

                $playIcon.addClass("show");
                $pauseIcon.removeClass("show");
                video.get(0).pause();
            }

            setTimeout(() => {drawerDisplay[i].style.height = 0}, 300)
            drawerDisplay[i].style.opacity = "0";
            drawerDisplay[i].children[1].classList.remove("open");
            drawerDisplay[i].children[1].style.transform = "translateX(100%)";
        });
    };
}

const $playVideo = $(".drawer-with-video-image .image_container .icon");
const $drawerPlayButton = $(".drawer-with-video-image .playButton");
$drawerPlayButton.click(function(e) {
    e.preventDefault();
});

$playVideo.click(function(e) {
    const video = $(e.target).parents("figure").children("video");
    var $playIcon = $(e.target).parents("figure").find(".playIcon");
    var $pauseIcon = $(e.target).parents("figure").find(".pauseIcon");

    if($playIcon.hasClass("show")) {
        // Play video
        video.get(0).play();
        var videoItem = video.get(0);

        //$(e.target).parents(".image_container").addClass("fixed")

        if(window.innerWidth < 600) {
            if (videoItem.requestFullscreen) {
                videoItem.requestFullscreen();
            } else if (videoItem.mozRequestFullScreen) {
                videoItem.mozRequestFullScreen();
            } else if (videoItem.webkitRequestFullscreen) {
                videoItem.webkitRequestFullscreen();
            } else if (videoItem.msRequestFullscreen) { 
                videoItem.msRequestFullscreen();
            }
        }
    } else {
        // Pause video
        video.get(0).pause();
        //$(e.target).parents(".image_container").removeClass("fixed")
    }

    $playIcon.toggleClass("show");
    $pauseIcon.toggleClass("show");
});