import { setupFiltering } from "./_block-retailers";

// Remove style attribute
/* $('#parent').attr('style', '') */

var $orderGuide = $('.order-guide');
var options = parseOrderOptions(window.location.search);
var steps = $('.stepping')
let state = 0;
let currentStep = options.step;
let title = '';
var $longString = $(".order-guide .step .options .option:last-of-type .title > h3 .option-title");
const $orderWrapper = $('.order-guide .order-wrapper');
var $retailersHeight = $(".retailers").outerHeight();

// Adjust order-wrapper height based on retailers content
$(".order-wrapper .retailers .order-near > a").click(function(e) {
    $orderWrapper.css("min-height", $(".order-wrapper .retailers .order-near > a").offset().top + 3000);
    setTimeout(() => { 
        var $retailersHeight = $(".retailers").outerHeight();
        $orderWrapper.css("min-height", $retailersHeight + 500);
    }, 600);
})
$(".order-wrapper .retailers .order-online > a").click(function(e) {
    $orderWrapper.css("min-height", $retailersHeight + 2500);
    setTimeout(() => { 
        var $retailersHeight = $(".retailers").outerHeight();
        $orderWrapper.css("min-height", $retailersHeight + 500);
    }, 400);
})
$(".order-wrapper .retailers .filter-search input").click(function(e) {
    setTimeout(() => { 
        var $retailersHeight = $(".retailers").outerHeight();
        $orderWrapper.css("min-height", $retailersHeight + 500);
    }, 400);
})
$(".order-wrapper .retailers .tab-map-list .map-tab").click(function(e) {
    const $retailerMap = $(".retailers .map-content .map");
    const $input = $(".retailers .search-inputs .search-city");
    const $button = $(".retailers .map-close");
    const $background = $(".retailers .white-background");

    $retailerMap.css({
        "position": "fixed",
        "top" : (window.scrollY) + 180,
        "height" : window.innerHeight - 180 
    })
    $input.css({
        "top" : (window.scrollY) + 64
    })
    $button.css({
        "top" : window.scrollY + 32
    })
    $background.css({
        "top" : window.scrollY - 32
    })
})

if(currentStep === 3) {
    var $retailersHeight = $(".retailers").outerHeight();
    $orderWrapper.css("min-height", $retailersHeight + 500);
    $(window).resize(function() {
        var $retailersHeight = $(".retailers").outerHeight();
        $orderWrapper.css("min-height", $retailersHeight + 500);
    });
    var options = parseOrderOptions(window.location.search);
    if(options.installation === 0) {
        $(".order-online .accounts").slideDown();
        $(".order-online > a").addClass("only-home")
        $(".order-near").hide();
    }
}

if(currentStep) {
    $(window).resize(function() {
        if((currentStep === 2) || (currentStep === 1)) {
            if((window.innerWidth > 465) && (window.innerWidth < 600)) {
                $orderWrapper.css("min-height", "1400px");
            } else if((window.innerWidth > 600) && (window.innerWidth < 800)) {
                $orderWrapper.css("min-height", "1500px");
            } else if((window.innerWidth > 800) && (window.innerWidth < 1000)) {
                $orderWrapper.css("min-height", "1800px");  
            } else if((window.innerWidth > 1000) & (window.innerWidth < 2200)) {
                $orderWrapper.css("min-height", "1100px");
            } else if(window.innerWidth > 2200) {
                $orderWrapper.css("min-height", "1400px");
            } else {
                $orderWrapper.css("min-height", "1100px");
            }
        }
    });
}

if($longString.length !== 0) {
    var $shorterString = $longString.eq(0).html().replace(/\//g, "/&#8203;");
    $longString.eq(0).html($shorterString)
}

function moveToStep(clickedLink) {
    const orderOptions = parseOrderOptions(clickedLink);
    const newStep = orderOptions.step;
    $(".order-near .map-search-content").slideUp(100);

    $('.step').removeClass('show');
    $('.step').removeClass('hide');
    $('.step').removeClass('reverse');
    $('.order-wrapper').removeClass('taller');

    if((newStep === 2) || (newStep === 1)) {
        $(".retailers .order-near > a").removeClass("active");
        $(".order-online > a").removeClass("active");
        $(".order-online .accounts").slideUp();
    }
    if (newStep === 3) {
        $('.step').addClass('hide')
        $('#step-2').addClass('show');
        $('#step-2').removeClass('hide');
        $('.order-wrapper').addClass('taller');
        $(".order-near .list .accounts.show").show();

        setupFiltering();
        setTimeout(() => { 
            var $retailersHeight = $(".retailers").outerHeight();
            $orderWrapper.css("min-height", $retailersHeight + 500);
        }, 400);

        var options = parseOrderOptions(window.location.search);
        if(options.installation === 0) {
            $(".order-online .accounts").slideDown();
            $(".order-online > a").addClass("only-home")
            $(".order-near").hide();
        } else {
            $(".order-online > a").removeClass("only-home") 
            $(".order-online .accounts").hide();
            $(".order-near").show()
            $(".order-near .accounts").hide();
        }
        state = 2;
    } else if (newStep === 2 && currentStep > 2) {
        $('#step-2').addClass('hide reverse');
        $('#step-1').addClass('show reverse');
        $('#step-').addClass('hide');
        if((window.innerWidth > 465) && (window.innerWidth < 600)) {
            $orderWrapper.css("min-height", "1400px");
        } else if((window.innerWidth > 600) && (window.innerWidth < 800)) {
            $orderWrapper.css("min-height", "1500px");
        } else if((window.innerWidth > 800) && (window.innerWidth < 1000)) {
            $orderWrapper.css("min-height", "1800px");
        } else if((window.innerWidth > 1000) & (window.innerWidth < 2200)) {
            $orderWrapper.css("min-height", "1100px");
        } else if(window.innerWidth > 2200) {
            $orderWrapper.css("min-height", "1400px");
        } else {
            $orderWrapper.css("min-height", "1100px");
        }

        state = 1
    } else if (newStep === 2) {
        const $step1 = $('#step-1');
        $('#step-').addClass('hide')
        $step1.addClass('show');
        if((window.innerWidth > 465) && (window.innerWidth < 600)) {
            $orderWrapper.css("min-height", "1400px");
        } else if((window.innerWidth > 600) && (window.innerWidth < 800)) {
            $orderWrapper.css("min-height", "1500px");
        } else if((window.innerWidth > 800) && (window.innerWidth < 1000)) {
            $orderWrapper.css("min-height", "1800px");
        } else if((window.innerWidth > 1000) & (window.innerWidth < 2200)) {
            $orderWrapper.css("min-height", "1100px");
        } else if(window.innerWidth > 2200) {
            $orderWrapper.css("min-height", "1400px");
        } else {
            $orderWrapper.css("min-height", "1100px");
        }
        
        $step1.find('a.stepping').each(function(index, element) {
            const currentOrderOptions = parseOrderOptions(element.href);
            element.href = '?step=' + currentOrderOptions.step + '&product=' + orderOptions.product + '&installation=' + currentOrderOptions.installation;
        });
        
        state = 1
    } else {
        if (currentStep === 3) {
            $('#step-2').addClass('hide reverse');
        } else {
            $('#step-1').addClass('hide reverse');
        }
        if(newStep !== 3) {
            if((window.innerWidth > 465) && (window.innerWidth < 600)) {
                $orderWrapper.css("min-height", "1400px");
            } else if((window.innerWidth > 600) && (window.innerWidth < 800)) {
                $orderWrapper.css("min-height", "1500px");
            } else if((window.innerWidth > 800) && (window.innerWidth < 1000)) {
                $orderWrapper.css("min-height", "1800px");
            } else if((window.innerWidth > 1000) & (window.innerWidth < 2200)) {
                $orderWrapper.css("min-height", "1100px");
            } else if(window.innerWidth > 2200) {
                $orderWrapper.css("min-height", "1400px");
            } else {
                $orderWrapper.css("min-height", "1100px");
            }       
        }

        $('#step-').addClass('show');
    }
    $(".order-near .list .accounts.show").show();
    currentStep = newStep;
}

if($(".order-guide").length > 0) {
    window.addEventListener('popstate', function (event) {
        moveToStep(window.location.href);
    });
}

steps.click(function(el){
    el.preventDefault()
    let clickedLink = el.currentTarget.getAttribute('href');
    let url = el.currentTarget.getAttribute('href');
    history.pushState(state,title, url)
    moveToStep(clickedLink);
})

$orderGuide.find('.step:nth-child(' + options.step + ')').addClass('show');

export function parseOrderOptions(str) {
    var components = str.split('&');
    var step = 1;
    var product = '';
    var installation = '';
    if (components.length > 0) {
        var stepComponents = components[0].split('=');
        step = parseInt(stepComponents[1]) || 1;
    }

    if (components.length > 1) {
        var productComponents = components[1].split('=');
        product = productComponents[1].length > 0 ? productComponents[1] : '';
    }

    if (components.length > 2) {
        var installationComponent = components[2].split('=');
        installation = installationComponent[1].length > 0 ? installationComponent[1] : '';
    }

    return {
        step: step,
        product: product,
        installation: installation
    }
}
