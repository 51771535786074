$('.product-comparison .expandable-section h3 a').click(function (e) {
    e.preventDefault();

    var $this = $(this);
    $this.parents('.expandable-section').toggleClass('active');
    $this.parents('.product-comparison').find('.expandable-content').slideToggle();

    var isActive = $this.parents('.expandable-section').hasClass('active');
    var $sectionTitle = $this.find('.expandable-title');
    var title = isActive ? $sectionTitle.data('close-title') : $sectionTitle.data('title');
    if (title) {
        $sectionTitle.text(title);
    }
});