var $emailSignature = $('.brand-email-signature');
var $form = $emailSignature.find('form');
var $signature = $emailSignature.find('#signature')
var $signatureContentAppleMail = $signature.find('.content.content-apple-mail');
var $signatureContentOutlook = $signature.find('.content.content-outlook');
var $signatureFeedback = $signature.find('.copy');
var $copyAppleMailButton = $emailSignature.find('button.copy-apple-mail');
var $copyOutlookButton = $emailSignature.find('button.copy-outlook');
var $buttons = $emailSignature.find('.copy-buttons button');

$form.submit(function(e) {
    e.preventDefault();

    var name = $form.find('#name').val() || 'Your name';
    var title = $form.find('#title').val() || 'Your title';
    var phone = $form.find('#phone').val();
    var mobile = $form.find('#mobile').val();
    var email = $form.find('#email').val() || 'easee@easee.com';
    var country = $form.find('#country').val() || 'Norge';
    var additionalInfo = $form.find('#additional-info').val();

    var $nameDisplay = $emailSignature.find('.name-display');
    var $titleDisplay = $emailSignature.find('.title-display');
    var $phoneEmailWrapper = $emailSignature.find('.phone-email-wrapper');
    var $phoneDisplay = $emailSignature.find('.phone-display');
    var $mobileDisplay = $emailSignature.find('.mobile-display');
    var $emailDisplay = $emailSignature.find('.email-display');
    var $countryDisplay = $emailSignature.find('.country-display');
    var $countryWrapper = $emailSignature.find('.country-wrapper');
    var $additionalInfoDisplay = $emailSignature.find('.additional-info-display');
    var $additionalInfoWrapper = $emailSignature.find('.additional-info-wrapper');

    $nameDisplay.text(name);
    $titleDisplay.text(title);
    $emailDisplay.html('<a href="' + email + '" style="color: #005AFF; text-decoration: none;">' + email + '</a>');
    $countryDisplay.text('Easee ' + country);
    $additionalInfoDisplay.text(additionalInfo);

    $phoneDisplay.remove();
    $mobileDisplay.remove();

    if (mobile) {
        $phoneEmailWrapper.prepend('<p class="mobile-display" style="line-height: 1.5; font-family: Arial, sans-serif; font-size: 12px; font-weight: 300; margin: 0;">M ' + mobile + '</p>');
    }

    if (phone) {
        $phoneEmailWrapper.prepend('<p class="phone-display" style="line-height: 1.5; font-family: Arial, sans-serif; font-size: 12px; font-weight: 300; margin: 0;">T ' + phone + '</p>');
    }

    if (country) {
        $countryWrapper.show();
    } else {
        $countryWrapper.hide();
    }

    if (additionalInfo) {
        $additionalInfoWrapper.show();
    } else {
        $additionalInfoWrapper.hide();
    }

    $buttons.attr('disabled', false);
})

$copyAppleMailButton.click(function(e) {
    copy($signatureContentAppleMail.html());
});

$copyOutlookButton.click(function(e) {
    copy($signatureContentOutlook.html());
});

function copy(content) {
    copyToClip(content);
    $signatureFeedback.css('display', 'flex');

    setTimeout(function() {
        $signatureFeedback.css('display', 'none');
    }, 2000);
}

function copyToClip(str) {
    if (navigator.clipboard?.write) {
        const type = "text/html";
        const blob = new Blob([str], {type});
        const data = [new ClipboardItem({[type]: blob})];

        navigator.clipboard.write(data);
    } else {
        function listener(e) {
            e.clipboardData.setData("text/html", str);
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
        }

        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);
    }
}
