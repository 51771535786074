import Glide from '@glidejs/glide';

function initTestimonials(id) {
    var testimonialsGlide = new Glide('#' + id, {
        type: 'carousel',
        startAt: 0,
        perView: 1,
        autoplay: 6000
    });

    var testimonials = document.getElementById(id);
    if (testimonials && testimonials.querySelector('.glide__slides').children.length > 1) {
        testimonialsGlide.mount();

        var glideNavigation = testimonials.querySelector('.glide-navigation');
        var glideItemImages = testimonials.parentNode.parentNode.parentNode.querySelectorAll('.title-as-background-image .image-container');

        testimonialsGlide.on('move', function (e) {
            var items = glideNavigation.querySelectorAll('a');
            items.forEach(function(item) {
                item.classList.remove('active');
            });

            if(glideItemImages.length > 0) {
                glideItemImages.forEach(function(e) {
                    e.classList.remove("active");
                });

                glideItemImages[testimonialsGlide.index].classList.add("active");
            }

            items[testimonialsGlide.index].classList.add('active');
        });

        glideNavigation.querySelectorAll('a').forEach(function(link) {
            link.addEventListener('click', function (e) {
                e.preventDefault();
                testimonialsGlide.go('=' + this.getAttribute('data-id'));
            });
        });
    }
}
window.initTestimonials = initTestimonials;

$('.default-content .expandable-section:not(.load-more) a.toggle-section').click(function(e) {
    e.preventDefault();

    $(this).parents('.expandable-section').toggleClass('active');
});

$('.default-content .expandable-section.load-more a').click(function(e) {
    e.preventDefault();
    const $this = $(this);
    const maxAmount = $this.parents(".portraits").attr("data-amount");
    let portraitsLength = $this.parents(".portraits").attr("data-length") / maxAmount;
    if(!Number.isInteger(portraitsLength)) {
        portraitsLength = Math.floor(portraitsLength);
    } else {
        portraitsLength = portraitsLength - 1;
    }
    const $container = $this.parents('.portraits');
    const $lengthOfOpen = $container.find("div.display-portraits:not(.hide)").length;
    const $nextOpen = $container.find("div.slide-container[data-index='" + $lengthOfOpen + "']");
    const $allSlideContainer = $container.find("div.slide-container");

    $nextOpen.find("div.display-portraits").removeClass("hide");
    $nextOpen.slideDown();

    if($this.parents('.expandable-section').hasClass("active")) {
        $this.parents('.expandable-section').removeClass('active');
        $allSlideContainer.find("div.display-portraits").addClass("hide");
        $allSlideContainer.slideUp();
    }
    if($lengthOfOpen === portraitsLength) {
        $this.parents('.expandable-section').addClass('active');
    }
});

$('.default-content .downloads p a').click(function(e) {
    e.preventDefault();

    // Is the current selection active before the click?
    var isActive = $(this).parents('li').hasClass('active');

    // Hide all sections
    $(this).parents('ul').find('> li').removeClass('active').find('ul').slideUp();

    // If the section was not active before, make it active
    if (!isActive) {
        $(this).parents('li').addClass('active');
        $(this).parent().siblings('ul').slideDown('fast');
    }
});

$('.default-content .qa p a').click(function(e) {
    e.preventDefault();

    // Is the current selection active before the click?
    var isActive = $(this).parents('li').hasClass('active');

    // Hide all sections
    $(this).parents('ul').find('> li').removeClass('active').find('ul').slideUp();

    // If the section was not active before, make it active
    if (!isActive) {
        $(this).parents('li').addClass('active');
        $(this).parent().siblings('ul').slideDown('fast');
    }
});

$('.default-content .qa .question h4 a').click(function(e) {
    e.preventDefault();

    // Is the current selection active before the click?
    var isActive = $(this).parents('li.question').hasClass('active');

    // Hide all sections
    $(this).parents('ul.questions').find('> li').removeClass('active').find('.answer').slideUp();

    // If the section was not active before, make it active
    if (!isActive) {
        $(this).parents('li.question').addClass('active');
        $(this).parent().siblings('.answer').slideDown('fast');
    }
});

$('.default-content .expand-other-section p a').click(function(e) {
    e.preventDefault();
    var $this = $(this);
    var title = $this.data('title');
    var titleOpen = $this.data('titleOpen');

    var isOpen = $this.hasClass('open');

    $this.find('span.title').html(!isOpen && titleOpen ? titleOpen : title);

    var id = $this.data('id');
    $('#' + id).slideToggle();

    $this.toggleClass('open');
});

const $firstContent = $('body:not(.brand) #content-section section:first-of-type');

if($firstContent.eq(0).hasClass("default-content")) {
    let $contentHeader = $firstContent.eq(0).find("h2");
    $contentHeader.replaceWith('<h1 class="' + $contentHeader.attr("class") + '">' + $contentHeader.html() + '</h1>')
};

var $countryOptions = $('.filters-active .filters .countries .country-options');
var $activeCountry = $('.filters-active .filters .countries .active');

var $cityOptions = $('.filters-active .filters .cities .city-options');
var $activeCity = $('.filters-active .filters .cities .active');

var $teamOptions = $('.filters-active .filters .teams .team-options');
var $activeTeam = $('.filters-active .filters .teams .active');

var $hiringContainer = $(".filters-active .hiring");

var amount = parseInt($hiringContainer.data("amount")) || 0;

var $selected_city = $('.default-content .filters.all .cities .city-options .selected a')
var $citySlug = $selected_city.data("slug");
$('.default-content .filters.all .cities .city-options .reset-data a').attr("data-slug", "")

var $selected_team = $('.default-content .filters.all .teams .team-options .selected a')
var $teamSlug = $selected_team.data("slug");

var selectedTags = {
    search: '',
    department: '',
    country: '',
    space: $citySlug || ''
};

var previousCountry = "";

if($citySlug !== "") {
}
if($teamSlug !== "") {
}
var $hiringPositions = $('.hiring_position');

if($hiringPositions.length > 0) {
    $hiringPositions.hide();
    $hiringPositions.slice(0, amount ? amount : $hiringPositions.length).show();
}

// Filter position function
function filterPositions() {
    const hasActiveFilters = selectedTags.search ||
        selectedTags.country ||
        selectedTags.department ||
        selectedTags.space;

    const $hiringPositions = $('.hiring_position');

    $hiringPositions.hide();

    if(selectedTags.country === '') {
        $cityOptions.find("a").show();
    } else {
        $cityOptions.find("a").hide();
        $cityOptions.find("a[data-all='spaces']").show();

        if(previousCountry !== selectedTags.country) {
            $cityOptions.children('li.selected').removeClass('selected');
            $cityOptions.find("a[data-all='spaces']").parent("li").addClass("selected");
            $activeCity.find('span').html($cityOptions.find("a[data-all='spaces']").html())
            selectedTags.space = "";
        } 

        $cityOptions.find(`a[data-citycountry='${selectedTags.country}']`).show();
    }

    if (!hasActiveFilters) {
        $hiringPositions.slice(0, amount ? amount : $hiringPositions.length).show();
        return;
    }

    let selectors = [];

    if (selectedTags.search) {
        selectors.push('[data-spacestitle*="' + selectedTags.search + '"]');
    }

    if (selectedTags.department) {
        selectors.push('[data-department="' + selectedTags.department + '"]');
    }

    if (selectedTags.country) {
        selectors.push('[data-countries*="' + selectedTags.country + '"]');
    }

    if (selectedTags.space) {
        selectors.push('[data-spaces*="' + selectedTags.space + '"]');
    }
    
    if(amount === 0) {
        $('.hiring_position' + selectors.join('')).show();
    } else {
        const $hiringPositions = $('.hiring_position' + selectors.join('')); 
        $hiringPositions.each(function(i) {
            if(i < amount) {
                $(this).show();
            } else {
                return;
            }
        })
    }
}

// Search filter event
$('.filters.all #position-search').keyup(function (evt) {
    selectedTags.search = evt.target.value.toLowerCase().replace("ø", "o").replace("å", "a").replace("æ", "ae");

    filterPositions();
});

// Generic function for selection of filter
function selectFilter($this, $active, $options, $tag, $class) {
    $active.find('span').html($this.html());
    previousCountry = selectedTags["country"];
    selectedTags[$tag] = $this.data("slug");
    $options.css({
        'display': 'none'
    });
    $options.children('li.selected').removeClass('selected');
    $this.parent('li').addClass('selected');
    $this.parents($class).find('.active').children('a').removeClass('open');
    $this.parents($class).find('.active').removeClass('options-open');
};

// Functions to close open selects
function removeOpenCountry() {
    $activeCountry.removeClass("options-open");
    $activeCountry.children('a').removeClass("open");
    $countryOptions.slideUp('fast');
}
function removeOpenCity() {
    $activeCity.removeClass("options-open");
    $activeCity.children('a').removeClass("open");
    $cityOptions.slideUp('fast');
}
function removeOpenTeam() {
    $activeTeam.removeClass("options-open");
    $activeTeam.children('a').removeClass("open");
    $teamOptions.slideUp('fast');
}

// Open selection dropdown
$activeTeam.find('a').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    $teamOptions.slideToggle('fast');
    $this.toggleClass('open');
    $this.parent().toggleClass('options-open');

    removeOpenCity();
    removeOpenCountry();
});

// Select dropdown options
$teamOptions.find('a').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    selectFilter($this, $activeTeam, $teamOptions, 'department', ".teams");
    filterPositions();
});

// Open selection dropdown
$activeCity.find('a').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    $cityOptions.slideToggle('fast');
    $this.toggleClass('open');
    $this.parent().toggleClass('options-open');

    removeOpenCountry();
    removeOpenTeam();
});

// Select dropdown options
$cityOptions.find('a').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    selectFilter($this, $activeCity, $cityOptions, 'space', ".cities");
    filterPositions();
});

// Open selection dropdown
$activeCountry.find('a').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    $countryOptions.slideToggle('fast');
    $this.toggleClass('open');
    $this.parent().toggleClass('options-open');

    removeOpenCity();
    removeOpenTeam();
});

// Select dropdown options
$countryOptions.find('a').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    selectFilter($this, $activeCountry, $countryOptions, 'country', ".countries");
    filterPositions();
});

// Replace h2 with h3 on brand communication page
if(window.location.href.indexOf("brand/communication") !== -1) {
    $(".default-content").each(function(e) {
        var $this = $(this);

        if($this.data("level") === "level_2") {
            var $h2Content = $this.find("h2").html();

            $this.find("h2").replaceWith('<h3 class="replacedH3">' + $h2Content + '</h3>')            
        }
    })
}

// Add support for search input 
import Fuse from 'fuse.js'
const $supportSearch = $('#support-search-content');

if($supportSearch.length > 0) {
    let isLoadingKnowledgeBase = false;
    let knowledgeBaseArticles = null;
    const $supportSearchInput = $('#support-search-content input');
    const $supportSearchResults = $('#support-search-content .results');
    const salesforceBaseURL = 'https://support.easee.com/help/s/';
    const zendeskBaseURL = 'https://easee.zendesk.com/hc/';
    const language = $supportSearch.data('language') || '';
    let resultIndex = -1;
    const supportSystem = $supportSearch.data('supportSystem') || 'salesforce';
    const isSalesforce = supportSystem === 'salesforce';

    const fuseOptions = {
        includeScore: true,
        keys: ['title', 'body']
    }

    $supportSearchInput.focus(() => {
        if (isSalesforce && !knowledgeBaseArticles && !isLoadingKnowledgeBase) {
            isLoadingKnowledgeBase = true;
            $.get('/cache/knowledgebase.json').then(function(data) {
                if (data) {
                    knowledgeBaseArticles = data;
                    supportSearch($supportSearchInput.val());
                }
            }).always(() => {
                isLoadingKnowledgeBase = false;
            });
        }

        resultIndex = -1;
        supportSearch($supportSearchInput.val());
    }).blur(() => {
        setTimeout(function() {
            $supportSearchResults.hide();
        }, 500);
    });

    const inputTimeout = 200;
    let inputTimer = null;
    $supportSearchInput.keyup(function(e) {
        const searchString = $(this).val();

        if (isSalesforce) {
            const specialKeys = [13, 38, 40];
            if (specialKeys.indexOf(e.keyCode) !== -1) {
                handleKeyEvent(e);
                return;
            }
            supportSearch(searchString);

            resultIndex = -1;
            moveResultSelection(0);
        } else {
            if (inputTimer) {
                clearTimeout(inputTimer);
            }

            inputTimer = setTimeout(function () {
                const specialKeys = [13, 38, 40];
                if (specialKeys.indexOf(e.keyCode) !== -1) {
                    handleKeyEvent(e);
                    return;
                }
                supportSearch(searchString);

                resultIndex = -1;
                moveResultSelection(0);
            }, inputTimeout);
        }
    });

    function supportSearch(input) {
        if (input.length < 2) {
            $supportSearchResults.hide();
            return;
        }

        if (isSalesforce) {
            const list = knowledgeBaseArticles || [];
            const languageFilteredList = list.filter(item => !language || item.language === language);
            const fuse = new Fuse(languageFilteredList, fuseOptions);
            const results = fuse.search(input);

            const resultList = results.map(result => '<li><a href="' + salesforceBaseURL + 'article/' + result.item.url + '?language=' + language + '">' + result.item.title + '</a></li>').slice(0, 10).join('');
            $supportSearchResults.find('ul').html(resultList);

            if (results.length > 0) {
                $supportSearchResults.show();
            } else {
                $supportSearchResults.hide();
            }

            if (resultIndex >= results.length) {
                resultIndex = -1;
                moveResultSelection(0)
            }
        } else {
            let data = new FormData();
            data.append('action', 'zendesk_search');
            data.append('s', input);
            data.append('locale', language);

            $.ajax({
                url: easee_scripts.ajax_url,
                type: 'POST',
                data: data,
                cache: false,
                processData: false,
                contentType: false,
                error: function () {
                    $supportSearchResults.hide();
                },
                complete: function (data) {
                    const results = data.responseJSON?.results;

                    if (results) {
                        const resultList = results.map(result => '<li><a href="' + result.url + '">' + result.title + '</a></li>').slice(0, 10).join('');
                        $supportSearchResults.find('ul').html(resultList);

                        if (results.length > 0) {
                            $supportSearchResults.show();
                        } else {
                            $supportSearchResults.hide();
                        }

                        if (resultIndex >= results.length) {
                            resultIndex = -1;
                            moveResultSelection(0)
                        }
                    } else {
                        $supportSearchResults.hide()
                    }
                }
            });
        }
    }

    function handleKeyEvent(e) {
        e.preventDefault();

        switch (e.keyCode) {
            case 13: // enter
                activateSelection(e);
                break;
            case 38: // key up
                moveResultSelection(-1);
                break;
            case 40: // key down
                moveResultSelection(1);
                break;
        }
    }

    function moveResultSelection(direction) {
        if (direction < 0) {
            resultIndex = Math.max(resultIndex - 1, -1);
        } else if (direction > 0) {
            resultIndex = Math.min(resultIndex + 1, $supportSearchResults.find('li').length - 1);
        }

        $supportSearchResults.find('li a').removeClass('focused');

        if (resultIndex > -1) {
            $supportSearchResults.find('li:nth-child(' + (resultIndex + 1) + ') a').addClass('focused');
        }
    }

    function activateSelection(e) {
        if (resultIndex === -1) {
            if (isSalesforce) {
                window.location = salesforceBaseURL + 'global-search/' + e.target.value;
            } else {
                window.location = zendeskBaseURL + language + '/search?query=' + e.target.value;
            }
            return;
        }

        $supportSearchResults.find('li:nth-child(' + (resultIndex + 1) + ') a')[0].click();
    }
}


$(".default-content .portraits .portrait > a").click(function(e) {
    e.preventDefault();
})
