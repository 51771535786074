const $locationOptions = $('.spaces-map .locations .location-options');
const $activeLocation = $('.spaces-map .locations .active');
let $activeMarker = "Glasgow";
let type = document.querySelector(".spaces-map .map-container");
const mapElement = document.getElementById("spaces-map");
const $locationOptionsListItems = $('.spaces-map .locations .location-options li a');
const $locationInformation = $('.spaces-map .select-container .location-information');

// If page has a map element
if(mapElement) {
    const $activeSite = window.location.href.split("/")[5];

    // Set type to multiple or single map 
    if(type.classList.contains("multiple")) {
        type = "multiple";
    } else {
        type = "single";
    };

    // Set activeMarker to the current site
    if($activeSite) {
        if($activeSite === "sandnes") {
            $activeMarker = "Sandnes (HQ)";
        } else {
            var $activeSiteNoFirstLetter = $activeSite.slice(1);
            var $capitalizeFirstLetter = $activeSite.charAt(0).toUpperCase();
            var $activeSiteLocation = $capitalizeFirstLetter + $activeSiteNoFirstLetter;
            $activeMarker = $activeSiteLocation.replace("o", "ø");
        }
    };
    
    // If on single location page, select corresponding location in dropdown menu
    if(type === "single") {
        $locationOptionsListItems.each(function(i) {
            const information = $locationInformation[i];

            // Set dropdown item with slug matching current site to selected
            if($(this).data().slug === $activeSite) {
                $(this).parent('li').addClass('selected');
                $activeLocation.find('span').html($(this).html());
            } else {
                $(this).parent('li').removeClass('selected');
            }

            // Show information corresponding to selected dropdown item
            if((information.dataset.slug === "Sandnes (HQ)") && ($activeMarker === "Sandnes (HQ)")) {
                information.style.display = "block";
            } else {
                if(information.dataset.slug.toLowerCase() === $activeSite) {
                    information.style.display = "block";
                } else {
                    information.style.display = "none";
                }
            }
        });
    };

    // Open dropdown
    $activeLocation.find('a').click(function (e) {
        e.preventDefault();
        const $this = $(this);
        $locationOptions.slideToggle('fast');
        $this.toggleClass('open');
        $this.parent().toggleClass('options-open');
    });

    // Select dropdown option, and add marker to map element
    $locationOptions.find('a').click(function (e) {
        e.preventDefault();
        const $this = $(this);
        $activeLocation.find('span').html($this.html());
        $activeMarker = $this.html();
        $locationOptions.css({
            'display': 'none'
        });
        
        // Display corresponding location information
        $locationOptionsListItems.each(function(i) {
            const information = $locationInformation[i];

            if(information.dataset.slug === $activeMarker.replace("ø", "o")) {
                information.style.display = "block";
            } else {
                information.style.display = "none";
            }
        });

        $locationOptions.children('li.selected').removeClass('selected');
        $this.parent('li').addClass('selected');
        $this.parents('.locations').find('.active').children('a').removeClass('open');
        $this.parents('.locations').find('.active').removeClass('options-open');

        // Reload map with new markers
        if(type === "multiple") {
            reloadMarkers("multiple");
        } else {
            reloadMarkers("single");
        }
    });

    const locationHref = window.location.href;
    let baseUrl = "";
    let baseUrlParts = locationHref.split("/");
    for(let i = 0; i < baseUrlParts.length; i++) {
        if(i === 5) {
            break;
        } else {
            if(i === 0) {
                baseUrl += baseUrlParts[i];
            } else {
                baseUrl += "/" + baseUrlParts[i];
            }
        }
    }

    let map;
    let latlngbounds;
    let markers = [];
    let locations = [];
    let singleLocation = ["Sandnes (HQ)", 58.85244, 5.73521, 2, baseUrl + "/sandnes"];
    let teamTailorLocations = window.spaces_locations;

    // Push locations from TeamTailor json to locations array
    for (let i=0; i<teamTailorLocations.length; i++) {
        const location = teamTailorLocations[i].attributes;

        if(baseUrl.indexOf("spaces") === -1 ) {
            if(location.city === "Sandnes") {
                const spacesLocation = ["Sandnes (HQ)", parseFloat(location.lat), parseFloat(location.long), 2, baseUrl + "/spaces/" + location.city.toLowerCase()];
                locations.push(spacesLocation);
            } else {
                const spacesLocation = [location.city, parseFloat(location.lat), parseFloat(location.long), 1, baseUrl + "spaces/" + location.city.toLowerCase().replace("ø", "o")];
                locations.push(spacesLocation);
            }
        } else {
            if(location.city === "Sandnes") {
                const spacesLocation = ["Sandnes (HQ)", parseFloat(location.lat), parseFloat(location.long), 2, baseUrl + "/" + location.city.toLowerCase()];
                locations.push(spacesLocation);
            } else {
                const spacesLocation = [location.city, parseFloat(location.lat), parseFloat(location.long), 1, baseUrl + "/" + location.city.toLowerCase().replace("ø", "o")];
                locations.push(spacesLocation);
            }
        }
    };

    // Create location markers on map
    function setMarkers(locationMarkers, typeInner, reload) {
        if(typeInner === "multiple") {
            let currentActiveLocation;

            // Loop over locations and create a marker for each
            for (let i = 0; i < locationMarkers.length; i++) {
                const location = locationMarkers[i];
                const scale = location[3] === 2 ? 0.7 : 0.5;
                if(location[3] === 2) {
                    currentActiveLocation = location;
                }

                const marker = new google.maps.Marker({
                    position: { lat: location[1], lng: location[2] },
                    map,
                    icon: {
                        path: "M39 20a19 19 0 1 1-38 0 19 19 0 1 1 38 0z",
                        fillColor: "#005AFF",
                        fillOpacity: 1,
                        strokeWeight: 0,
                        rotation: 0,
                        scale: scale,
                    },
                    title: location[0],
                    url: location[4]
                });

                markers.push(marker);
                google.maps.event.addListener(marker, 'click', function() {
                    window.location.href = this.url;
                });
            }

            if(reload === "reload") {
                moveCenter(currentActiveLocation[1], currentActiveLocation[2]);
            } else {
                map.setCenter(latlngbounds.getCenter());
                map.fitBounds(latlngbounds);
            }

        // Else if single
        } else {
            for(let i=0; i<locations.length; i++) {
                const location = locations[i];

                if(location[0] === $activeMarker) {
                    singleLocation = location;
                }
            }

            // Create marker
            const marker = new google.maps.Marker({
                position: { lat: singleLocation[1], lng: singleLocation[2] },
                map,
                icon: {
                    path: "M39 20a19 19 0 1 1-38 0 19 19 0 1 1 38 0z",
                    fillColor: "#005AFF",
                    fillOpacity: 1,
                    strokeWeight: 0,
                    rotation: 0,
                    scale: 0.5,
                },
                title: singleLocation[0],
                url: singleLocation[4]
            });

            markers.push(marker);
            google.maps.event.addListener(marker, 'click', function() {
                window.location.href = this.url;
            });

            moveCenter(singleLocation[1], singleLocation[2]);
        }
    }

    // Reload location markers on map
    function reloadMarkers(typeInner) {
        // Clear old markers
        for (let i=0; i<markers.length; i++) {
            markers[i].setMap(null);
        }
        markers = [];

        if(typeInner === "single") {
            for(let i=0; i<locations.length; i++) {
                const location = locations[i];

                if(location[0] === $activeMarker) {
                    singleLocation = location;
                }
            }

            if(singleLocation[0] === $activeMarker) {
                singleLocation[3] = 2;
            } else {
                singleLocation[3] = 1;
            }
        
            // Create marker
            setMarkers(singleLocation, "single");

        // Else if multiple
        } else {
            for(let i=0; i<locations.length; i++) {
                const location = locations[i];

                if(location[0] === $activeMarker) {
                    location[3] = 2;
                } else {
                    location[3] = 1;
                }
            }

            // Create markers
            setMarkers(locations, "multiple", "reload");
        }
    }

    // Multiple markers map, homepage
    function initMultiMap() { 
        var options = {
            zoom: 5,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP
            },
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            center: { lat: 57, lng: 4 },
            styles: [
                { elementType: 'geometry', stylers: [{ color: '#F2F2F2' }] },
                { elementType: 'labels.text.stroke', stylers: [{ color: '#F2F2F2' }] },
                { elementType: 'labels.text.fill', stylers: [{ color: '#F2F2F2' }] },
                {
                    featureType: 'administrative.locality',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#424242' }]
                },
                {
                    featureType: 'poi',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#424242' }]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'geometry',
                    stylers: [{ color: '#F2F2F2' }]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#6b9a76' }]
                },
                {
                    featureType: 'road',
                    elementType: 'geometry',
                    stylers: [{ color: '#FFFFFF' }]
                },
                {
                    featureType: 'road',
                    elementType: 'geometry.stroke',
                    stylers: [{ color: '#FFFFFF' }]
                },
                {
                    featureType: 'road',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#FFFFFF' }]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry',
                    stylers: [{ color: '#FFFFFF' }]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry.stroke',
                    stylers: [{ color: '#FFFFFF' }]
                },
                // {
                //   featureType: 'road.highway',
                //   elementType: 'labels',
                //   stylers: [{ color: '#B3B3B3' }]
                // },
                {
                    featureType: 'road.highway',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#FFFFFF' }]
                },
        
                {
                    featureType: 'transit',
                    elementType: 'geometry',
                    stylers: [{ color: '#F9F9F9' }]
                },
                {
                    featureType: 'transit.station',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#d59563' }]
                },
                {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [{ color: '#C0E5F4' }]
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#515c6d' }]
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.stroke',
                    stylers: [{ color: '#C0E5F4' }]
                }
            ]
        };
        latlngbounds = new google.maps.LatLngBounds(); 
       
        for (let i = 0; i < locations.length; i++) {
            const location = locations[i];
            const marker = new google.maps.Marker({
                position: { lat: location[1], lng: location[2] },
            });
            latlngbounds.extend(marker.position);
        }
        map = new google.maps.Map(document.getElementById("spaces-map"), options);
        setMarkers(locations, "multiple"); 
    }

    // Single marker map, location page
    function initSingleMap() {
        var options = {
            zoom: 9,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP
            },
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            center: { lat: singleLocation[1], lng: singleLocation[2] },
            styles: [
                { elementType: 'geometry', stylers: [{ color: '#F2F2F2' }] },
                { elementType: 'labels.text.stroke', stylers: [{ color: '#F2F2F2' }] },
                { elementType: 'labels.text.fill', stylers: [{ color: '#F2F2F2' }] },
                {
                    featureType: 'administrative.locality',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#424242' }]
                },
                {
                    featureType: 'poi',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#424242' }]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'geometry',
                    stylers: [{ color: '#F2F2F2' }]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#6b9a76' }]
                },
                {
                    featureType: 'road',
                    elementType: 'geometry',
                    stylers: [{ color: '#FFFFFF' }]
                },
                {
                    featureType: 'road',
                    elementType: 'geometry.stroke',
                    stylers: [{ color: '#FFFFFF' }]
                },
                {
                    featureType: 'road',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#FFFFFF' }]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry',
                    stylers: [{ color: '#FFFFFF' }]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry.stroke',
                    stylers: [{ color: '#FFFFFF' }]
                },
                // {
                //   featureType: 'road.highway',
                //   elementType: 'labels',
                //   stylers: [{ color: '#B3B3B3' }]
                // },
                {
                    featureType: 'road.highway',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#FFFFFF' }]
                },
        
                {
                    featureType: 'transit',
                    elementType: 'geometry',
                    stylers: [{ color: '#F9F9F9' }]
                },
                {
                    featureType: 'transit.station',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#d59563' }]
                },
                {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [{ color: '#C0E5F4' }]
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#515c6d' }]
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.stroke',
                    stylers: [{ color: '#C0E5F4' }]
                }
            ]
        };
        map = new google.maps.Map(document.getElementById("spaces-map"), options);
        setMarkers(singleLocation, "single");  
    }

    // Refocus the center of map, with marker change
    function moveCenter(lat, lng) {
        const center = new google.maps.LatLng(lat, lng);
        map.panTo(center);
    }
    
    window.initSingleMap = initSingleMap;
    window.initMultipleMap = initMultiMap;
}
