const $partners = $('.block-partner');
const country = localStorage.getItem('country');

if ($partners.length > 0) {
    if (country && country !== 'null') {
        var countryNames = window.countryMapping && window.countryMapping[country] && window.countryMapping[country]['salesforceCountryNames'] ? window.countryMapping[country]['salesforceCountryNames'].split(',') : null;

        if (countryNames) {
            $('.block-partner').each(function (index, element) {
                var $this = $(element);
                if (countryNames.indexOf($this.data('country')) !== -1) {
                    $this.show();
                }
            });
        }
    } else {
        $('.block-partner').show();
    }
}
