const $expandFacts = $(".post-facts .expandable-section");

if($expandFacts.length !== 0) {
    $('.post-facts .expandable-section .svg-container > a').click(function(e) {
        e.preventDefault();
    });
    // Toggle expandable content state on mobile
    $('.post-facts .expandable-section').click(function(e) {
        if ($(this).hasClass("active")) {
            $(this).next("p").removeClass("active");
            $(this).siblings( ".facts-open-title" ).removeClass("active");
            $(this).siblings( ".content" ).removeClass("active");
        } else {
            $(this).next("p").addClass("active");
            $(this).siblings( ".facts-open-title" ).addClass("active");
            $(this).siblings( ".content" ).addClass("active");
        }
        $(this).toggleClass('active');
    });
}