$('.product-options .option-selector a').click(function(e) {
    e.preventDefault();

    var $this = $(this);

    $this.siblings('input').prop('checked', 'checked');
    $this.siblings('input').trigger('change');
});

$('.product-options .option-selector input').change(function(e) {
    var $this = $(this);
    var index = $this.data('index');

    var $options = $(this).parents('.product-options');
    $options.find('.option').hide();
    $options.find('.option.option-' + index).show();
});