const $habeSchonEinmalInstalliertOption = $('.easee-form #installationsszenarien-habe-schon-einmal-installiert input');
const $grosshandelOption = $('.easee-form #installationsszenarien-grosshandel');

$grosshandelOption.hide();

$habeSchonEinmalInstalliertOption.change(function() {
    if ($(this).prop('checked')) {
        $grosshandelOption.show();
    } else {
        $grosshandelOption.find('input').prop('checked', false);
        $grosshandelOption.hide();
    }
})
