import Glide from "@glidejs/glide";
import { intersectionObserve } from "./_intersection-observer";

function initProductImagesSlider(id,perView,autoplay) {
    var productImagesGlide = new Glide('#' + id, {
        type: 'carousel',
        startAt: 0,
        perView: perView,
        breakpoints: {
            1000: {
                perView: 1
            }
        },
        gap: 0,
        autoplay: autoplay || 3500,
        animationDuration: 1000,
        animationTimingFunc: 'cubic-bezier(0.465, 0.840, 0.440, 1.000)'
    });
    var productImages = document.getElementById(id);
    let counter = 1;
    if (productImages && productImages.querySelector('.glide__slides').children.length > 1) {
        const $productImages = $(productImages);
        const totalSLides = $productImages.data('count')
        var parentContainer = $(productImages).parents('a');
        parentContainer.find('.step-circle').removeClass('active')
        parentContainer.find('#number-1').addClass('active');
        productImagesGlide.mount();
        productImagesGlide.on('run.before',function(move){
            if(counter < totalSLides  ){
                counter = counter+1
                parentContainer.find('.step-circle').removeClass('active')
                parentContainer.find('#number-'+counter).addClass('active')
            }else{
                counter = 1;
                parentContainer.find('.step-circle').removeClass('active')
                parentContainer.find('#number-'+counter).addClass('active')
            }
        })

        var count = parseInt(productImages.getAttribute('data-count')) || 0;

        if (!isSliderVisible(productImagesGlide) || (window.innerWidth < 1000 && count <= 1) || (window.innerWidth >= 1000 && count <= 2)) {
            productImagesGlide.update({autoplay:false});
            productImagesGlide.pause()

        }
        if(isSliderVisible(productImagesGlide) || (window.innerWidth < 1000 && count <= 1) || (window.innerWidth >= 1000 && count <= 2)){
            productImagesGlide.play();
        }
        if($(productImages).find('.glide-navigation').length) {
            productImages.querySelector('.glide-nav-button.glide-prev').addEventListener('click', function (e) {
                e.preventDefault();
                productImagesGlide.go('<');
            });

            productImages.querySelector('.glide-nav-button.glide-next').addEventListener('click', function (e) {
                e.preventDefault();
                productImagesGlide.go('>');
            });
        }
    }

    intersectionObserve(productImages, 0, function(element, intersectionRatio) {
        if (intersectionRatio > 0 && (window.innerWidth < 1000 && count > 1) || (window.innerWidth >= 1000 && count > 2)) {
            productImagesGlide.play();
            productImagesGlide.update({autoplay:'3500'});
        }
        if(intersectionRatio <= 0){
            productImagesGlide.update({autoplay:false});
        }
    });
}


function isSliderVisible(element) {
    return window.scrollY + window.innerHeight >= element.offsetTop + element.offsetHeight / 2;
}

window.initProductImagesSlider = initProductImagesSlider;
