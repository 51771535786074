import bodymovin from 'lottie-web'

var animations = document.querySelectorAll('.lottie-animation');
var $blockAnimation = $('.lottie-animation');
var animation;

function setup() {
    animations.forEach(element => loadAnimation(element));
}

setup();
setTimeout(setup, 1000);
setTimeout(setup, 3000);

function loadAnimation(element) {
    if (element.classList.contains('loaded')) {
        return;
    }

    var animationName = element.getAttribute('data-animation');

    animation = bodymovin.loadAnimation({
        container: element, // Required
        path: '/wp-content/themes/grensesnitt/animations/' + animationName + '.json', // Required
        renderer: 'svg', // Required
        loop: false, // Optional
        autoplay: false, // Optional
        name: "", // Name for future reference. Optional.
    });

    element.classList.add('loaded');

    goToFrame();
}

$(window).scroll(function(e) {
    goToFrame();
});

function goToFrame() {
    if (!animation) {
        return;
    }

    var animationOffsetTop = $blockAnimation.offset().top;
    var animationBlockHeight = $blockAnimation.height();
    var scrollTop = $(window).scrollTop();
    var windowHeight = window.innerHeight;

    var offsetBottom = scrollTop + windowHeight;
    var animationLength = animation.getDuration(true);

    var progress = Math.min(Math.max(offsetBottom - animationBlockHeight / 4 - animationOffsetTop, 0) / (windowHeight - animationBlockHeight / 4), 1);
    var frame = progress * animationLength;

    animation.goToAndStop(frame, true);


}



