import Swipe from 'swipejs'

var animationLength = 500; // Change this in _grensesnitt-lightbox.scss as well if changed

var $grensesnittLightboxContainer = $('.gl-container');
var $navPrev = $('.gl-nav-prev > a');
var $navNext = $('.gl-nav-next > a');
var swipe;
var $lightboxes = $('.lightbox');

if($lightboxes.length > 0) {
    $lightboxes.click(function (e) {
        e.preventDefault();

        showLightbox();

        if (swipe) {
            swipe.kill();
            swipe = null;
        }

        var $lightboxImages = $(this).parents('.images-content').find('.lightbox');

        $lightboxImages.each(function (index, el) {
            var $el = $(el);
        
            $el.attr('data-index', index);
        });

        addImages($lightboxImages);

        swipe = new Swipe($('.gl-swipe')[0], {
            draggable: true,
            continuous: true,
            disableScroll: true
        });

        $grensesnittLightboxContainer[0].addEventListener('touchmove', function (e) {
            e.preventDefault();
        });

        var index = parseInt($(this).data('index')) || 0;
        swipe.slide(index, 1);
    });

    $('.gl-container .gl-close').click(function (e) {
        e.preventDefault();

        hideLightbox();
    });


    $('.gl-container .overlay').click(function(e) {
        hideLightbox();
    });

    $navNext.click(function (e) {
        e.preventDefault();

        swipe.next();

        $('.gl-container .gl-inner .gl-image .gl-swipe-wrap .download-meta .resolutions a').removeClass('active');
        $('.gl-container .gl-inner .gl-image .gl-swipe-wrap .download-meta .resolutions .resolution-wrapper .wrapper').removeClass('show');
        $('.gl-container .gl-inner .gl-image .gl-swipe-wrap .download-meta .resolutions .resolution-wrapper a.original-image').addClass('active');
        $('.gl-container .gl-inner .gl-image .gl-swipe-wrap .download-meta .resolutions .resolution-wrapper .wrapper.original').addClass('show');
    });

    $navPrev.click(function (e) {
        e.preventDefault();

        swipe.prev();

        $('.gl-container .gl-inner .gl-image .gl-swipe-wrap .download-meta .resolutions a').removeClass('active');
        $('.gl-container .gl-inner .gl-image .gl-swipe-wrap .download-meta .resolutions .resolution-wrapper .wrapper').removeClass('show');
        $('.gl-container .gl-inner .gl-image .gl-swipe-wrap .download-meta .resolutions .resolution-wrapper a.original-image').addClass('active');
        $('.gl-container .gl-inner .gl-image .gl-swipe-wrap .download-meta .resolutions .resolution-wrapper .wrapper.original').addClass('show');
    });

    function addImages($lightboxImages) {
        var $swipeWrap = $('.gl-swipe-wrap');
        $swipeWrap.html('');

        $lightboxImages.each(function (index, el) {
            var url = el.href;
            var siteUrl = el.getAttribute("data-site-link");
            var partnerClass, portraitClass;
            if (el.classList.contains('partner-image')) {
                partnerClass = 'partner-image';
            }
            else {
                partnerClass = '';
            }
            if (el.classList.contains('portrait')) {
                portraitClass = 'portrait';
            }
            else {
                portraitClass = '';
            }
            var imageName = el.querySelector("img").getAttribute('data-name');
            var imageDate = el.querySelector("img").getAttribute('data-date');
            var imageCategories = el.querySelector("img").getAttribute('data-categories');
            var imageTags = el.querySelector("img").getAttribute('data-tags');
            var highRes = el.querySelector("img").getAttribute('data-high-res');
            var highResWidth = el.querySelector("img").getAttribute('data-high-width');
            var highResHeight = el.querySelector("img").getAttribute('data-high-height');
            var highResSize = el.querySelector("img").getAttribute('data-hi-res-filesize');
            var originalRes = el.querySelector("img").getAttribute('data-original-res');
            var originalResWidth = el.querySelector("img").getAttribute('data-original-width');
            var originalResHeight = el.querySelector("img").getAttribute('data-original-height');
            var originalResSize = el.querySelector("img").getAttribute('data-orig-size');
            var webRes = el.querySelector("img").getAttribute('data-web-res');
            var webResWidth = el.querySelector("img").getAttribute('data-web-width');
            var webResHeight = el.querySelector("img").getAttribute('data-web-height');
            var webResSize = el.querySelector("img").getAttribute('data-web-filesize');
            $swipeWrap.append('<div><div class="image ' + portraitClass + '"><img src="' + url + '" /></div><div class="right-content"><div class="meta-top"><h2 class="filename">' + imageName + '</h2><div class="meta-wrapper ' +  partnerClass + '"><div class="tags"><p class="title">Category, tag</p><p>' + imageCategories + (imageCategories && imageTags ? ', ' : '') + imageTags + '</p></div><div class="date"><p class="title">Date</p><p>' + imageDate + '</p></div><div class="notice"><p class="title">Partner image</p><div class="wrapper"><span>NOTICE! </span>Partner images can not be shared with our dealers or others. These images are only to be used for Easee.</div></div></div></div><div class="download-meta"><p class="title">Choose</p><div class="resolutions"><div class="resolution-wrapper"><a class="original-image active" href="#" data-href="' + originalRes + '">Original</a><div class="wrapper original show"><div class="dimensions"><span>Dimensions: </span>' + originalResWidth + 'x' + originalResHeight + 'px' + '</div><div class="image-size"><span>Size: </span>' + originalResSize + '</div></div></div><div class="resolution-wrapper"><a href="#" data-href="' + highRes + '">HI-res</a><div class="wrapper"><div class="dimensions"><span>Dimensions: </span>' + highResWidth + 'x' + highResHeight + 'px' + '</div><div class="image-size"><span>Size: </span>'+ highResSize +'</div></div></div><div class="resolution-wrapper"><a href="#" data-href="' + webRes + '">Web</a><div class="wrapper"><div class="dimensions"><span>Dimensions: </span>' + webResWidth + 'x' + webResHeight + 'px' + '</div><div class="image-size"><span>Size: </span>' + webResSize +'</div></div></div></div><a class="download-link" href="' + originalRes + '" download target="_blank" rel="noopener norefferer"><img src="' + siteUrl + '/images/arrow-down-blue.svg"/>Download</a></div></div></div>');
        })

        $('.gl-container .gl-inner .gl-image .gl-swipe-wrap .download-meta .resolutions a').click(function(e) {
            e.preventDefault();
            $('.gl-container .gl-inner .gl-image .gl-swipe-wrap .download-meta .resolutions a').removeClass('active');
            $(this).addClass('active');
            $('.gl-container .gl-inner .gl-image .gl-swipe-wrap .download-meta .resolutions .resolution-wrapper .wrapper').removeClass('show');
            $(this).parent().children('.wrapper').addClass('show');
            $(this).parents('.download-meta').children('.download-link').attr("href", $(this).data('href'));
        });
    }

    function hideLightbox() {
        $grensesnittLightboxContainer.removeClass('fade');
        var $overlay = $grensesnittLightboxContainer.find('.overlay');
        $overlay.css('background', 'rgba(0, 0, 0, 0)');

        setTimeout(function () {
            $grensesnittLightboxContainer.removeClass('show');
            $('body').removeClass('noscroll');
        }, animationLength);
    }

    function showLightbox() {
        $grensesnittLightboxContainer.addClass('show');
        var $overlay = $grensesnittLightboxContainer.find('.overlay');
        $overlay.css('background', 'rgba(0, 0, 0, 0.8)');

        setTimeout(function () {
            $grensesnittLightboxContainer.addClass('fade');

            setTimeout(function () {
                $('body').addClass('noscroll');
            }, animationLength);
        }, 10);
    }
}