var $expandableContent = $('.brand.design-elements #page-wrapper .brand-downloads-expandable-and-link .expandable-content');
var $iconElements = $('.brand.design-elements #page-wrapper #download-pictograms').nextUntil('.brand.design-elements #page-wrapper .brand-icons-ui-icons').addBack().add('.brand.design-elements #page-wrapper .brand-icons-ui-icons');
var $5thElements = $('.brand.design-elements #page-wrapper #element-bright-blue').nextUntil('.brand.design-elements #page-wrapper #illustrations').addBack();
var $allDefaultContentIDs = [];
var $allDefaultContent = $(".brand.templates #page-wrapper .default-content.brand-fullscreen");
var $hr = '<hr class="wp-block-separator is-style-wide space-below">';

$allDefaultContent.each(function( index ) {
    var ID = $( this ).attr("id");
    $allDefaultContentIDs.push(ID);
});

var elementObjects = [];
var elementAnchors = [];

for(let i = 0; i < $allDefaultContentIDs.length; i++) {
    if(i === $allDefaultContentIDs.length - 1) {
        elementObjects[$allDefaultContentIDs[i]] = $(`.brand.templates #page-wrapper #${$allDefaultContentIDs[i]}`).nextAll(':lt(4)').eq(3).nextAll().addBack();
        elementAnchors[$allDefaultContentIDs[i]] = $('.brand-menu ul li.' + $allDefaultContentIDs[i]);

        $(`.brand.templates .brand-downloads-expandable-and-link .expandable-content#${$allDefaultContentIDs[i]}-content`).append($hr, elementObjects[$allDefaultContentIDs[i]]);
        elementObjects[$allDefaultContentIDs[i]].hide();
    } else {
        elementObjects[$allDefaultContentIDs[i]] = $(`.brand.templates #page-wrapper #${$allDefaultContentIDs[i]}`).nextAll(':lt(4)').eq(3).nextUntil(`.brand.templates #page-wrapper #${$allDefaultContentIDs[i+1]}`).addBack();
        elementAnchors[$allDefaultContentIDs[i]] = $('.brand-menu ul li.' + $allDefaultContentIDs[i]);
        
        $(`.brand.templates .brand-downloads-expandable-and-link .expandable-content#${$allDefaultContentIDs[i]}-content`).append($hr, elementObjects[$allDefaultContentIDs[i]]);
        elementObjects[$allDefaultContentIDs[i]].hide();
    }
}

var $iconsAnchors = $('.brand-menu ul li.icon-library');
var $5thAnchors = $('.brand-menu ul li.5th-element');
$('.brand.design-elements .brand-downloads-expandable-and-link .expandable-content#icons-content').append($iconElements);
$('.brand.design-elements .brand-downloads-expandable-and-link .expandable-content#5th-content').append($5thElements);
$iconElements.hide();
$5thElements.hide();

$('.brand-downloads-expandable-and-link .expandable-section a.expand-link').click(function(e) {
    e.preventDefault();
    showSection($(this));
});

function showSection($el) {
    if($el.parents('.brand-downloads-expandable-and-link').prev().prev().is('#icon-library')) {
        $iconElements.show();
        $iconsAnchors.css({
            'position': 'initial',
            'max-height': '30px',
            'opacity': 1
        });
    } else if($el.parents('.brand-downloads-expandable-and-link').prev().prev().prev().is('#5th-element')) {
        $5thElements.show();
        $5thAnchors.css({
            'position': 'initial',
            'max-height': '30px',
            'opacity': 1
        });
    }
    else {
        for(let i = 0; i < $allDefaultContentIDs.length; i++) {
            if($el.parents('.brand-downloads-expandable-and-link').prev().prev().is(`#${$allDefaultContentIDs[i]}`)) {
                elementObjects[$allDefaultContentIDs[i]].show();
                elementAnchors[$allDefaultContentIDs[i]].css({
                    'position': 'initial',
                    'max-height': '30px',
                    'opacity': 1
                });
            } 
        }
    }
    
    $el.parents('.expandable-section').toggleClass('active');

    if($el.parents('.expandable-section').hasClass('active')) {
        $el.children('.exp-link-text').hide();
        $el.children('.exp-close-text').show();
        if($el.parents('.brand-downloads-expandable-and-link').prev().prev().is('#icon-library')) {
            $iconsAnchors.css({
                'position': 'initial',
                'max-height': '30px',
                'opacity': 1
            });
        }
        else if($el.parents('.brand-downloads-expandable-and-link').prev().prev().prev().is('#5th-element')) {
            $5thAnchors.css({
                'position': 'initial',
                'max-height': '30px',
                'opacity': 1
            });
        }
        else {
            for(let i = 0; i < $allDefaultContentIDs.length; i++) {
                if($el.parents('.brand-downloads-expandable-and-link').prev().prev().is(`#${$allDefaultContentIDs[i]}`)) {
                    elementAnchors[$allDefaultContentIDs[i]].css({
                        'position': 'initial',
                        'max-height': '30px',
                        'opacity': 1
                    });
                } 
            }
        }
    }
    else {
        $el.children('.exp-close-text').hide();
        $el.children('.exp-link-text').show();
        if($el.parents('.brand-downloads-expandable-and-link').prev().prev().is('#icon-library')) {
            $iconsAnchors.css({
                'max-height': '0',
                'opacity': 0
            });
            setTimeout(function() {
                $iconsAnchors.css({
                    'position': 'absolute',
                });
            },200);
        }
        else if($el.parents('.brand-downloads-expandable-and-link').prev().prev().prev().is('#5th-element')) {
            $5thAnchors.css({
                'max-height': '0',
                'opacity': 0
            });
            setTimeout(function() {
                $5thAnchors.css({
                    'position': 'absolute',
                });
            },200);
        }
        else {
            for(let i = 0; i < $allDefaultContentIDs.length; i++) {
                if($el.parents('.brand-downloads-expandable-and-link').prev().prev().is(`#${$allDefaultContentIDs[i]}`)) {
                    elementAnchors[$allDefaultContentIDs[i]].css({
                        'max-height': '0',
                        'opacity': 0
                    });
                    setTimeout(function() {
                        elementAnchors[$allDefaultContentIDs[i]].css({
                            'position': 'absolute',
                        });
                    },200);
                } 
            }
        }
    }
}

// Make h2 inside expandable section h3
function convertH2ToH3() {
    $('.brand .brand-downloads-expandable-and-link .expandable-content .default-content').find('h2').replaceWith(function () {
        return `<h3 class="${this.className}">${$(this).html()}</h3>`;
    });
};
convertH2ToH3();

// Scroll to section inside expandable parent
if (window.location.hash) {
    const hash = window.location.hash.substring(1); // Remove # at the start
    const $section = $('.brand-downloads-expandable-and-link #' + hash);
    if ($section.length > 0) {
        const $expandLink = $section.parents('.brand-downloads-expandable-and-link').find('.expandable-section a.expand-link');
        showSection($expandLink);
        setTimeout(function() {
            window.scrollToAnchor(window.location.hash);
        }, 300);
    }
}
