var $options = $('.landing-page .go .options');
var $goButton = $('.landing-page .link-button');
var $activeDiv = $('.landing-page .go .active');
var $activeA = $('.landing-page .go .active a');

// Open options and add/remove keydown event listener
$activeDiv.find('a').click(function(e) {
    e.preventDefault();
    var $this = $(this);
    $options.slideToggle('fast');
    $this.toggleClass('open');
});

// Select option and remove keydown event listener
$options.find('a').click(function(e) {
    $options.slideToggle('fast');
    $activeA.toggleClass('open');
    var $this = $(this);
    $goButton.attr('href', $this.attr('href'));
    $activeDiv.find('span').html($this.html());
});

if ($options.length > 0) {
    // Event listener that scrolls to matching list item on keydown
    document.addEventListener('keydown', function listenForKeyboard(event) {
        $options.find('li').each(function (index, item) {
            if (event.key === item.classList.value) {
                var container = $options,
                    id = "#" + item.id,
                    $scrollTo = $(id);

                // Scroll to first item matching keyboard input
                container.animate({
                    scrollTop: $scrollTo.offset().top - container.offset().top + container.scrollTop() - 10
                });
                return false;
            }
        })
    });
}

if($options) {
    var $windowLocation = window.location.href;

    // Get location information based on IP address
    if ($windowLocation.includes("easee.com") && window.dbip) {
        dbip.getVisitor({ tryNavigatorGeolocation: false }).then(findCountryName);
    }

    // Use countryCodeArr to find country name based on country code from response
    function findCountryName(response) {
        const countryCode = response?.countryCode?.toLowerCase();
        if (!countryCode) return;


        const $listItem = $options.find('li[data-country-code="' + countryCode + '"');
        if ($listItem.length > 0) {
            var $link = $listItem.find('a');

            // Do we have more than one list item with this country code? Look for language match.
            if ($listItem.length >= 2) {
                const userLang = navigator.language.toLowerCase();
                const $matchingItems = $listItem.filter(function(index, item) {
                    const itemLang = $(item).data('languageCode');
                    return itemLang === userLang;
                });

                // Did we find exactly one hit matching both country code and language code? Use this.
                if ($matchingItems.length === 1) {
                    $link = $($matchingItems[0]).find('a');
                }
            }

            $activeDiv.find('span').html($link.html());
            $goButton.attr("href", $link.attr('href'));
        }
    }
}
