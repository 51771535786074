import bodymovin from 'lottie-web'

const $chargerModelViewWrapper = $('.charger-model-view-wrapper');
const $animations = document.querySelectorAll('.lottie-animation-charger');
let animationObjects = [];
let activeColor = 0;

if($chargerModelViewWrapper.length > 0) {
    $(window).resize((e) => {
        updateFrames($chargerModelViewWrapper[0].scrollLeft);
    })

    $chargerModelViewWrapper[0].addEventListener('scroll', function(e) {
        const offset = e.target.scrollLeft;
        updateFrames(offset)
        updateTitle();
    });

    const $navigationLi = $('.charger-model-view-navigation li');
    const $navigationA = $('.charger-model-view-navigation li a');
    $navigationA.click(function(e) {
        e.preventDefault();

        const id = parseInt(e.target.getAttribute('data-id') || e.target.parentElement.getAttribute('data-id'));
        if (!isNaN(id)) {
            navigateTo(id)
        }
    });

    setup();
    setTimeout(setup, 1000);
    setTimeout(setup, 3000);

    function setup() {
        $animations.forEach((element, i) => loadAnimation(element, i));
    }

    // set up animation object for each element
    function loadAnimation(element, i) {
        if (element.classList.contains('loaded')) {
            return;
        }
        var animationName = element.getAttribute('data-animation');

        const animationObject = bodymovin.loadAnimation({
            container: element, // Required
            path: '/wp-content/themes/grensesnitt/animations/' + animationName + '.json', // Required
            renderer: 'svg', // Required
            loop: false, // Optional
            autoplay: false, // Optional
            name: "", // Name for future reference. Optional.
        });
        animationObject.setSpeed(1.4);
        animationObjects.push(animationObject);
        element.classList.add('loaded');

        updateFrames(0);
    }

    // Update frame based on scroll position
    function updateFrames(offset) {
        const viewPortWidth = window.innerWidth;
        const frameCount = 60;
        const scaleTarget = viewPortWidth < 600 ? 1.3 : 1.0;

        for (let i = 0; i < 5; i++) {
            if (!animationObjects[i]) {
                return;
            }

            const progress = 0.5 + offset / viewPortWidth - (0.5 * i);
            const frame = Math.max(0, Math.min(frameCount, progress * frameCount))

            if (scaleTarget > 0) {
                const scale = Math.max(1, Math.min(scaleTarget, (1 - Math.abs(progress - 0.5)) * scaleTarget));
                $($animations[i]).css('transform', 'scale(' + scale + ')');
            }

            animationObjects[i].goToAndStop(frame, true);
        }
    }

    function navigateTo(index) {
        activeColor = Math.max(0, Math.min(index, $navigationLi.length - 1));

        const viewPortWidth = window.innerWidth;
        const x = Math.round(activeColor * 0.5 * viewPortWidth);
        const currentScrollLeft = $chargerModelViewWrapper.scrollLeft();

        const scrollFactor = Math.abs(x - currentScrollLeft) / (viewPortWidth * 0.5); // Factor animation length by this to keep constant animation speed
        const animationLength = 350;

        $chargerModelViewWrapper.animate({
            scrollLeft: x
        }, animationLength * scrollFactor);

        renderNavigation();
    }

    function renderNavigation() {
        $navigationLi.removeClass('active');
        $($navigationLi[activeColor]).addClass('active');
    }

    // Change active color name
    function updateTitle() {
        const $colorName = $(".color-navigation li.active a").attr('data-color');
        $(".color-name").hide();
        $(`.color-name-container .${$colorName}`).show();
    }

    // Cursor transitions 
    if($(".cursor-container").length !== 0) {
        var cursor = document.querySelector(".cursor-container");
        var cursorSVGLeft = document.querySelector(".cursor-container svg.left");
        var cursorSVGRight = document.querySelector(".cursor-container svg.right");
        var cursorP = document.querySelector(".cursor-container p");

        $(document).mousemove((e) => {
            if(e.target.nodeName === "image") {
                cursor.style.top = e.originalEvent.clientY - 40 +'px';
                cursor.style.left = e.originalEvent.clientX - 40 +'px';
            } else {
                cursor.style.top = e.originalEvent.clientY - 40 +'px';
                cursor.style.left = e.originalEvent.clientX - 40 +'px';
                $(".cursor-container").css("transfrom", "translateX(40px)")
            }
        })

        $('.charger-model-view .charger-model-view-arrow-right').mouseenter((elem) => {
            if (activeColor === $navigationLi.length - 1) { // Last slide
                cursor.classList.remove('visible');
                $('.charger-model-view .charger-model-view-arrow-right').addClass("inactive");
                cursorSVGLeft.classList.remove("fadeIn");
                cursorSVGRight.classList.remove("fadeIn");
                cursorP.classList.remove("fadeIn");
            } else {
                $('.charger-model-view .charger-model-view-arrow-right').removeClass("inactive");
                cursor.classList.add('visible');
                cursorSVGRight.classList.add("fadeIn");
                cursorSVGLeft.classList.remove("fadeIn");
                cursorP.classList.remove("fadeIn");
            }

            cursor.querySelector(".inner").style.width = "48px"
            cursor.querySelector(".inner").style.height = "48px"
        }) 
        $('.charger-model-view .charger-model-view-arrow-left').mouseenter((elem) => {
            if (activeColor === 0) { // First slide
                
                $('.charger-model-view .charger-model-view-arrow-left').addClass("inactive");
                cursor.classList.remove('visible');
                cursorSVGLeft.classList.remove("fadeIn");
                cursorSVGRight.classList.remove("fadeIn");
                cursorP.classList.remove("fadeIn");
            } else {
                $('.charger-model-view .charger-model-view-arrow-left').removeClass("inactive");
                cursor.classList.add('visible');
                cursorSVGLeft.classList.add("fadeIn");
                cursorSVGRight.classList.remove("fadeIn");
                cursorP.classList.remove("fadeIn");
            }
            
            cursor.querySelector(".inner").style.width = "48px"
            cursor.querySelector(".inner").style.height = "48px"
        }) 
        $(".charger-model-view .slide").mouseenter((elem) => {
            cursor.classList.add('visible');
            
            cursorP.classList.add("fadeIn");
            cursorSVGRight.classList.remove("fadeIn");
            cursorSVGLeft.classList.remove("fadeIn");

            cursor.querySelector(".inner").style.width = "80px"
            cursor.querySelector(".inner").style.height = "80px"
        })
        $(".charger-model-view .model-container").mouseleave((elem) => {
            cursor.classList.remove('visible');
        })

        $('.charger-model-view .charger-model-view-arrow-right').click(function() {
            const newActiveColor = activeColor + 1;
            navigateTo(newActiveColor);
        });

        $('.charger-model-view .charger-model-view-arrow-left').click(function() {
            const newActiveColor =activeColor - 1;
            navigateTo(newActiveColor);
        });

        let startX = 0;
        let startCursorX = 0;
        let movedX = 0;
        let isMovingX = false;

        function dragStart(e) {
            startX = $chargerModelViewWrapper.scrollLeft();
            const touches = event.touches;
            startCursorX = touches && touches.length > 0 ? touches[0].pageX : e.clientX;
            movedX = 0;
            isMovingX = true;
        }

        function dragMove(e) {
            if (!isMovingX) {
                return;
            }

            const touches = event.touches;
            const clientX = touches && touches.length > 0 ? touches[0].pageX : e.clientX;
            movedX = startCursorX - clientX;

            if (touches && touches.length > 0 && movedX) {
                e.preventDefault();
                e.stopPropagation();

                chargerModelViewWrapper.scrollTo(startX + movedX, 0);

                return false;
            }

            chargerModelViewWrapper.scrollTo(startX + movedX, 0);
        }

        function dragEnd() {
            const currentXPosition = chargerModelViewWrapper.scrollLeft;
            let newXPosition = currentXPosition / (window.innerWidth / 2);

            if (movedX > 0) {
                newXPosition = Math.ceil(newXPosition);
            } else {
                newXPosition = Math.floor(newXPosition);
            }

            navigateTo(newXPosition);

            startX = 0;
            movedX = 0;
            isMovingX = false;
        }

        function preventScroll(e) {
            if (e.deltaX) {
                e.preventDefault();
                e.stopPropagation();
                return false;
            }

            return true;
        }

        const chargerModelViewWrapper = $('.charger-model-view-wrapper')[0];
        chargerModelViewWrapper.addEventListener('wheel', preventScroll, {passive: false});
        chargerModelViewWrapper.addEventListener('mousedown', dragStart);
        chargerModelViewWrapper.addEventListener('touchstart', dragStart);
        chargerModelViewWrapper.addEventListener('mousemove', dragMove);
        chargerModelViewWrapper.addEventListener('touchmove', dragMove, {passive: false});
        chargerModelViewWrapper.addEventListener('mouseup', dragEnd);
        chargerModelViewWrapper.addEventListener('touchend', dragEnd);
        chargerModelViewWrapper.addEventListener('mouseleave', dragEnd);
    }
}
