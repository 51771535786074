var isOpening = false;
$('.news-preview article a').hover(function(e) {
    var $this = $(this);
    var $articles = $this.parents('.posts').find('article');
    var $currentArticle = $this.parents('article');

    $articles.addClass('fade-down');
    $currentArticle.removeClass('fade-down');

    var thumbnail = $currentArticle.data('thumbnail');
    if (thumbnail) {
        var $imagePreview = $this.parents('.news-preview').find('.image-preview');
        $imagePreview.find('.image').html('<img src="' + thumbnail + '"/>');
        $imagePreview.addClass('show');
        setTimeout(function() {
            $imagePreview.addClass('fade-in');
        }, 10);
    }

    isOpening = true;

}, function() {
    var $this = $(this);
    var $articles = $this.parents('.posts').find('article');
    $articles.removeClass('fade-down');

    var $imagePreview = $this.parents('.news-preview').find('.image-preview');
    $imagePreview.removeClass('fade-in');
    setTimeout(function() {
        if (!isOpening) {
            $imagePreview.removeClass('show');
        }
    }, 300);

    isOpening = false;

});