$('.play-video').click(function(e) {
    e.preventDefault();
    var $this = $(this);
    var $video = $this.siblings('video');
    var $previewImage = $this.siblings('img.preview-image');

    $video.attr('controls', 'controls');
    $video[0].play();

    $previewImage.hide();

    $this.addClass('fade');
    setTimeout(function() {
        $this.remove();
    }, 300);
});
