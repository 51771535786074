import Glide from '@glidejs/glide';
function initOurCustomers(id) {
    var ourCustomersGlide = new Glide('#' + id, {
        type: 'slider',
        startAt: 0,
        perView: 1,
        autoplay: 6000,
    });

    var customers = document.getElementById(id);
    var customersParent = customers.parentNode.parentNode;
    if (customers && customers.querySelector('.glide__slides').children.length > 1) {
        ourCustomersGlide.mount();
        var glideNavigation;
        if(customers.classList.contains("mobile")) {
            glideNavigation = customersParent.querySelector('.glide-navigation-mobile');
        } else {
            glideNavigation = customersParent.querySelector('.glide-navigation');
        }

        ourCustomersGlide.on('move', function (e) {
            var items = glideNavigation.querySelectorAll('a');
            items.forEach(function(item) {
                item.classList.remove('active');
            });

            items[ourCustomersGlide.index].classList.add('active');
        });

        glideNavigation.querySelectorAll('a').forEach(function(link) {
            link.addEventListener('click', function (e) {
                e.preventDefault();
                ourCustomersGlide.go('=' + this.getAttribute('data-id'));
            });
        });
    }
}

window.initOurCustomers = initOurCustomers;