$('.brand-list-types .expandable-section a.expand-link').click(function(e) {
    e.preventDefault();
    showSection($(this));
});
$expandableContentList = $('.brand-list-types .expandable-section .expandable-content .expandable-list');
$expandableContentList.hide(); 

function showSection($el) {
    $el.parents(".expandable-section").find(".expandable-list").show()
    $el.parents('.expandable-section').toggleClass('active');
    if($el.parents('.expandable-section').hasClass('active')) {
        $el.children('.exp-link-text').hide();
        $el.children('.exp-close-text').show();
    }
    else {
        $el.children('.exp-close-text').hide();
        $el.children('.exp-link-text').show();
    }
}