
var $informationModal = $('#information-modal');

$('#page-close-information-modal').click(function(e) {
    e.preventDefault();
    closeInformationModal();
});

$informationModal.click(function(e) {
    if ($(e.target).parents('#information-modal').length) {
        return;
    }

    closeInformationModal();
});

export function showInformationModal(title, content) {
    $informationModal.find('.title').html(title);
    $informationModal.find('.information-content').html(content);
    $informationModal.addClass('show');
    setTimeout(function() {
        $informationModal.addClass('animate-show');
    }, 10);
}

export function closeInformationModal() {
    $informationModal.removeClass('animate-show');
    setTimeout(function() {
        $informationModal.removeClass('show');
    }, 300);
}