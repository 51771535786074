import './_information-modal'
import {showInformationModal} from "./_information-modal";

$('.page-links a[href="#"]').click(function(e) {
    e.preventDefault();
})
$('.page-links a[href="#information"]').click(function (e){
    e.preventDefault()

    var $slideshowInfo = $(e.currentTarget).siblings('.slideshow-information');
    showInformationModal($slideshowInfo.find('.title').html(), $slideshowInfo.find('.slideshow-content').html());
});

$('.page-links a').hover(function() {
    var $overlay = $(this).find('.overlay');

    var opacity = $overlay.data('hoverOpacity');
    if (opacity !== undefined) {
        $overlay.css('background', 'rgba(0, 0, 0, ' + opacity + ')');
    }
}, function() {
    var $overlay = $(this).find('.overlay');
    var opacity = $overlay.data('regularOpacity');

    if (opacity !== undefined) {
        $overlay.css('background', 'rgba(0, 0, 0, ' + opacity + ')');
    }
})