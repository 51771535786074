import { intersectionObserve } from "./_intersection-observer";
import bodymovin from 'lottie-web';

var $titleContainerJobPage = $('.banner .job_page h1');
function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

if (isMobile() === true) {
    $('.banner .image').on('click', function () {
        const videoElement = document.querySelector('.banner .image video');
        if(videoElement) {
            if (!!(videoElement.currentTime > 0 && !videoElement.paused && !videoElement.ended && videoElement.readyState > 2)) {
                videoElement.pause();
            }
            else {
                videoElement.play();
            }
        }
    });
}
if($titleContainerJobPage.length !== 0) {
    var $titleSpan = $titleContainerJobPage[0].innerHTML.split("</span>")[0].trim() + "</span>";
    var $titleLength = $titleContainerJobPage[0].innerHTML.split("/span>")[1].trim();

    // Split title find middle most word and add break
    if($titleLength.length !== 0) {
        if($titleLength.length > 20) {
            var titleWordsArray = $titleLength.split(" ");
            var middle = titleWordsArray.length / 2;
            titleWordsArray.splice(middle,0,"<br/>");
            var result = titleWordsArray.join(" ")
            $titleContainerJobPage.html($titleSpan + result);
        };
    }
}

// Change focus point and image url on resize
const $bannerImg = $(".banner .image img");
if($bannerImg.length > 0) {
    const $mobileX = $bannerImg.data("mobilex");
    const $mobileY = $bannerImg.data("mobiley");
    const $desktopX = $bannerImg.data("desktopx");
    const $desktopY = $bannerImg.data("desktopy");
    const $desktopImageUrl = $bannerImg.data("desktopimage");
    const $mobileImageUrl = $bannerImg.data("mobileimage");

    if(($mobileX !== $desktopX) || ($mobileY !== $desktopY) || ($mobileImageUrl?.length > 0)) {
        $(document).ready(function() {
            if($( window ).width() <= 600) {
                if(($mobileX !== $desktopX) || ($mobileY !== $desktopY)) {
                    $bannerImg.css("object-position", $mobileX + "% " + $mobileY + "%");
                }
                if($mobileImageUrl?.length > 0) {
                    $bannerImg.attr("src", $mobileImageUrl);
                }
            } else {
                if(($mobileX !== $desktopX) || ($mobileY !== $desktopY)) {
                    $bannerImg.css("object-position", $desktopX + "% " + $desktopY + "%");
                }
                if($mobileImageUrl?.length > 0) {
                    $bannerImg.attr("src", $desktopImageUrl);
                }
            }
            
        });
        $(window).resize(function(){
            if($( window ).width() <= 600) {
                if(($mobileX !== $desktopX) || ($mobileY !== $desktopY)) {
                    $bannerImg.css("object-position", $mobileX + "% " + $mobileY + "%");
                }
                if($mobileImageUrl?.length > 0) {
                    $bannerImg.attr("src", $mobileImageUrl);
                }
            } else {
                if(($mobileX !== $desktopX) || ($mobileY !== $desktopY)) {
                    $bannerImg.css("object-position", $desktopX + "% " + $desktopY + "%");
                }
                if($mobileImageUrl?.length > 0) {
                    $bannerImg.attr("src", $desktopImageUrl);
                }
            }
        });
    }
}

$('.banner .mute-unmute a').click(function(e) {
    e.preventDefault();

    var $this = $(this);
    var $banner = $this.parents('.banner');

    var $video = $banner.find('video');
    if( $video.prop('muted') ) {
        $video.prop('muted', false);
        //$banner.find('h3').css('opacity', 0);
        //$banner.find('.title').css('opacity', 0);
    } else {
        $video.prop('muted', true);
        //$banner.find('h3').css('opacity', 1);
        //$banner.find('.title').css('opacity', 1);
    }

    $this.siblings('a').show();
    $this.hide();
});

$('.banner a.link-button[href="#"]').click(function(e) {
    e.preventDefault();
})

var $bannerAndContent = $('.banner + .default-content');
var $titleWrapper = $('.banner').find('.content > div');
var $bannerTitle = $('.banner .title');
var defaultButtonColorTransition = '0.3s background cubic-bezier(0.645, 0.045, 0.355, 1), 0.3s border cubic-bezier(0.645, 0.045, 0.355, 1), 0.3s color cubic-bezier(0.645, 0.045, 0.355, 1)';
var startColor = 255;
var targetColor = 51;
var linkTargetColor = $('.banner a.link-button[href="#"]').length > 0 ? [targetColor, targetColor, targetColor] : [0, 90, 255];
var linkStartColor = [255, 255, 255];
var $defaultContentTitle = $('.banner + .default-content .title h2');
var $defaultContentContent = $('.banner + .default-content .content');
var colorDiff = startColor - targetColor;

if (($bannerAndContent.length > 0) && ($defaultContentTitle.length === 0)) {
    var titleOffsetTop = $titleWrapper.find('> *:first-child').offset().top;
    var defaultContentTitleOffsetTop = $defaultContentContent.offset().top;
    var titleColorTransitionHeight = 200; // Use 200px for color change

    $(window).scroll(moveBannerTitle);

    $(window).resize(function() {
        $titleWrapper.css('transform', 'none');

        titleOffsetTop = $titleWrapper.find('> *:first-child').offset().top;
        defaultContentTitleOffsetTop = $defaultContentContent.offset().top;

        moveBannerTitle();
    });

    function moveBannerTitle() {
        if (window.innerWidth < 1000) {
            $titleWrapper.css('transform', 'none');
            $titleWrapper.find('h1, h2, h3, .paragraph-supertitle').css('color', 'inherit');
            $titleWrapper.find('.link-button').css({
                'border-color': '#fff',
                'color': 'inherit'
            });
            $bannerTitle.css('width', 'inherit');

            return;
        }

        if ($('.banner .image-link:not(.has-description)').length > 0) {
            if(window.location.href.indexOf("/position/") !== -1) {
                if(window.innerWidth > 1600) {
                    $bannerTitle.css('width', '48%');
                    $(".banner .image-link:not(.has-description) .title").css("flex-wrap", "no-wrap");
                } else {
                    $bannerTitle.css('width', '30%');
                    $(".banner .image-link:not(.has-description) .title").css("flex-wrap", "wrap");
                }

                $(".banner .image-link:not(.has-description) .links").css("margin-top", "1rem");
            } else {
                $bannerTitle.css('width', '48%');
            }
        }

        var scrollTop = $(this).scrollTop();
        var diff = defaultContentTitleOffsetTop - titleOffsetTop;
        var speed = 2;
        var progress = scrollTop / titleColorTransitionHeight / speed;

        var translateY = Math.min(scrollTop / speed, diff);
        var rgbColor = Math.max(startColor - progress * colorDiff, targetColor);
        var newColor = 'rgb(' + rgbColor + ', ' + rgbColor + ', ' + rgbColor + ')';

        var linkR = Math.max(linkStartColor[0] - progress * (linkStartColor[0] - linkTargetColor[0]), linkTargetColor[0]);
        var linkG = Math.max(linkStartColor[1] - progress * (linkStartColor[1] - linkTargetColor[1]), linkTargetColor[1]);
        var linkB = Math.max(linkStartColor[2] - progress * (linkStartColor[2] - linkTargetColor[2]), linkTargetColor[2]);
        var newLinkBorderColor = 'rgb(' + linkR + ', ' + linkG + ', ' + linkB + ', 1)';

        $titleWrapper.find('.link-button').css('transition', progress > 0 && scrollTop / speed < diff ? 'none' : defaultButtonColorTransition);
        $titleWrapper.css('transform', 'translateY(' + translateY + 'px)');
        $titleWrapper.find('h1, h2, h3, .link-button, .paragraph-supertitle span').css('color', newColor);

        $titleWrapper.find('.link-button').css({
            'border-color': newLinkBorderColor,
            'color': newLinkBorderColor
        });
    }

    moveBannerTitle();
}

var $fullWidthBanner = $('.banner.fullwidth');

if ($fullWidthBanner.length > 0) {
    var $bannerDescription = $fullWidthBanner.find('.description');
    var $bannerContent = $bannerDescription.find('> div');
    var $bannerContentP = $bannerContent.find('.large-paragraph');
    var $bannerContentH2 = $bannerContent.find('h2');
    var $bannerContentText = $bannerContent.find('div');
    var adminBarHeight = $('.admin-bar').length > 0 ? 32 : 0;
    var $linkButton = $fullWidthBanner.find('.link-button');

    $(window).scroll(handleScroll);
    $(window).resize(handleScroll);

    function handleScroll() {
        if (window.innerWidth < 1000) {
            $bannerDescription.css('opacity', 1);
            $bannerContent.css('transform', 'none');
            $fullWidthBanner.removeClass('flow');
            return;
        }

        var scrollTop = $(this).scrollTop();
        var progress = Math.min(scrollTop / window.innerHeight * 3, 1);

        $bannerDescription.css('opacity', progress);
        $bannerContent.css('transform', 'translateY(' + Math.max(window.innerHeight / 2 - scrollTop, 0) + 'px)');

        if (scrollTop > window.innerHeight / 6) {
            $bannerContentP.addClass('show');
            $bannerContentH2.addClass('show');
        } else {
            $bannerContentH2.removeClass('show');
            $bannerContentP.removeClass('show')
        }

        if (scrollTop > window.innerHeight / 3) {
            $bannerContentText.addClass('show');
        } else {
            $bannerContentText.removeClass('show');
        }

        if (scrollTop + window.innerHeight / 2 - adminBarHeight > window.innerHeight) {
            $fullWidthBanner.addClass('flow');
            $linkButton.addClass('active');
        } else {
            $fullWidthBanner.removeClass('flow');
            $linkButton.removeClass('active');
        }
    }

    handleScroll();
    setTimeout(handleScroll, 200);
}

$('.play-banner-video').click(function(e) {
    e.preventDefault();
    var $this = $(this);
    var $video = $this.siblings('video');
    var $previewImage = $this.siblings('img.preview-image');

    $video[0].play();

    $previewImage.hide();

    $this.addClass('fade');
    setTimeout(function() {
        $this.remove();
    }, 300);

    $('.banner .play-pause').show();
});

$('.banner .play-pause a').click(function(e) {
    e.preventDefault();
    var $play = $('.banner .play-pause .play');
    var $pause = $('.banner .play-pause .pause');

    var $video = $(this).parents('.banner').find('video');
    var video = $video[0];
    if (video.paused) {
        video.play();
        $play.hide();
        $pause.show();
    } else {
        video.pause();
        $play.show();
        $pause.hide();
    }
})

var playOnceVideos = document.querySelectorAll('.banner video.no-loop');
intersectionObserve(playOnceVideos, 0, (target, intersectionRatio) => {
    if (intersectionRatio > 0 && target.paused) {
        target.play();
    }
});


var animations = document.querySelectorAll('.banner .banner-animation');
var animation;

function setup() {
    animations.forEach(element => loadAnimation(element));
}

setup();
setTimeout(setup, 1000);
setTimeout(setup, 3000);

function loadAnimation(element) {
    if (element.classList.contains('loaded')) {
        return;
    }

    var data = element.querySelector('.data code');

    animation = bodymovin.loadAnimation({
        container: element, // Required
        animationData: JSON.parse(data.innerHTML),
        renderer: 'svg', // Required
        loop: true, // Optional
        autoplay: true, // Optional
        name: "", // Name for future reference. Optional.
    });

    element.classList.add('loaded');
}




