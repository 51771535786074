import Glide from '@glidejs/glide';
import { Anchors, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'

let transition_timeout; 
var solutionsGlideMultiple = [];
var settings = {
    type: 'slide',
    startAt: 0,
    animationDuration: 1000,
    gap: 40,
    perView: 3,
    dragThreshold: 0,
    peek: { before: 128, after: 128 },
    breakpoints: {
        1300: {
            dragThreshold: 0,
            gap: 40,
            perView: 3,
            peek: { before: 64, after: 64 },
        },
        1000: {
            dragThreshold: 120,
            gap: 16,
            perView: 2,
            peek: { before: 32, after: 32 },
        },
        600: {
            dragThreshold: 120,
            gap: 16,
            perView: 1,
            peek: { before: 16, after: 40 },
        }
    },
};

function initSolutions(id) {
    const slider = document.querySelector(`#${id}`);
    const paragraphs = Array.from(slider.querySelectorAll("p"));
    const h3Titles = Array.from(slider.querySelectorAll("h3"));
    const drawerElements = Array.from(slider.querySelectorAll(".drawer"));

    var solutionsGlide = new Glide('#' + id, settings);
    solutionsGlideMultiple.push(solutionsGlide);
    var images = document.getElementById(id);
    
    if (images && images.querySelector('.glide__charging-solutions .glide__slides').children.length > 1) {
        if(images.querySelector('.glide__charging-solutions .glide__slides').children.length > 3) {
            const $chargingSolutionsBlock = $(`.${id}`);
            $chargingSolutionsBlock.css("padding-right", 0);

            solutionsGlide.update({
                peek: { before: 128, after: 128 },
                dragThreshold: true,
                breakpoints: {
                    1300: {
                        dragThreshold: true,
                        gap: 40,
                        perView: 3,
                        peek: { before: 64, after: 64 },
                    },
                    1000: {
                        dragThreshold: true,
                        gap: 16,
                        perView: 2,
                        peek: { before: 32, after: 32 },
                    },
                    600: {
                        dragThreshold: true,
                        gap: 16,
                        perView: 1,
                        peek: { before: 16, after: 40 },
                    }
                },
            });
        }
        solutionsGlide.on("swipe.end", () => {
            clearTimeout(transition_timeout);
            paragraphs.forEach(paragraph => paragraph.classList.add("no-trans"));
            h3Titles.forEach(title => title.classList.add("no-trans"));
            drawerElements.forEach(drawer => drawer.classList.add("no-events"));
            transition_timeout = setTimeout(_ => {
                paragraphs.forEach(paragraph => paragraph.classList.remove("no-trans"));
                drawerElements.forEach(drawer => drawer.classList.remove("no-events"));
                h3Titles.forEach(title => title.classList.remove("no-trans"));
            }, 1200);
        });

        solutionsGlide.mount({ Anchors, Breakpoints });
        
        var glideNavigation = images.querySelector('.glide__charging-solutions .glide-navigation');

        solutionsGlide.on('move', function (e) {
            var items = glideNavigation.querySelectorAll('a');
            items.forEach(function(item) {
                item.classList.remove('active');
            });

            items[solutionsGlide.index].classList.add('active');
        });

        glideNavigation.querySelectorAll('a').forEach(function(link, i) {
            link.addEventListener('click', function (e) {
                e.preventDefault();
                solutionsGlide.go('=' + this.getAttribute('data-id'));
            });
        });

        // Hide/show slideshow navigation on resize
        function setNavItems(glideNavigation) {

            for(let i = 0; i < glideNavigation.length; i++) {
                const navigationContainer = glideNavigation[i];

                if(glideNavigation[i].querySelectorAll('a').length < 4) { 
                    // Hide navigation if on desktop and there are less than 4 slides
                    if($( window ).width() >= 1001) {
                        navigationContainer.style.display = "none";

                        glideNavigation[i].querySelectorAll('a').forEach(function(link, i) {  
                            link.style.display = "none";
                        });
                        solutionsGlide.go("=0");
                    } else if($( window ).width() >= 601) {
                        // Show first and every other navigation item on laptop
                        glideNavigation[i].querySelectorAll('a').forEach(function(link, i) {  
                            navigationContainer.style.display = "flex";
                            if(i % 2 === 0) {
                                link.style.display = "inline-block";
                            } else {
                                link.style.display = "none";
                            }
                        });
                    } else {
                        // Show all navigation items on mobile
                        glideNavigation[i].querySelectorAll('a').forEach(function(link, i) {  
                            link.style.display = "inline-block";
                        }); 
                    }   
                } else { 
                    if($( window ).width() >= 1001) {
                        // Show first and every fourth navigation item if there are 4 or more slides on desktop
                        glideNavigation[i].querySelectorAll('a').forEach(function(link, i) {  
                            if(i % 3 === 0) {
                                link.style.display = "inline-block";
                            } else {
                                link.style.display = "none";
                            }
                        });
                    } else if($( window ).width() >= 601) {
                        // Show first and every other navigation item on laptop
                        glideNavigation[i].querySelectorAll('a').forEach(function(link, i) {  
                            if(i % 2 === 0) {
                                link.style.display = "inline-block";
                            } else {
                                link.style.display = "none";
                            }
                        });
                    } else {
                        // Show all navigation items on mobile
                        glideNavigation[i].querySelectorAll('a').forEach(function(link, i) {  
                            link.style.display = "inline-block";
                        }); 
                    }    
                }            
            }
        }

        // Hide/show slideshow navigation on load and resize
        $( document ).ready(function() {
            setNavItems(document.querySelectorAll('.glide__charging-solutions .glide-navigation'));
            $( window ).resize(function() {
                setNavItems(document.querySelectorAll('.glide__charging-solutions .glide-navigation'));
            })
        }) 
    }
}

window.initSolutions = initSolutions;

var allSlides = document.querySelectorAll('.charging-solutions .glide__charging-solutions');

// Temporary fix to DragTreshold breakpoints responsiveness
if(allSlides?.length > 0) {
    $( window ).resize(function() {
        for(let i = 0; i < allSlides.length; i++) {
            var slideIdGlobal = solutionsGlideMultiple[i];
            var slideIdGlobalSelector = solutionsGlideMultiple[i].selector;
            var slideId = slideIdGlobalSelector.replace("#","");

            if(document.querySelectorAll(slideIdGlobalSelector + ' .glide__slides .glide__slide').length === 3) {
                var width = $(window).width();
                 
                if(width < 1000) {
                    slideIdGlobal.destroy();
                    var solutionsGlide = new Glide(slideIdGlobalSelector, {
                        type: 'slide',
                        startAt: 0,
                        animationDuration: 1000,
                        gap: 0,
                        perView: 3,
                        dragThreshold: true,
                        peek: { before: 128, after: 128 },
                        breakpoints: {
                            1300: {
                                dragThreshold: true,
                                //gap: 0 // 40,
                                perView: 3,
                                peek: { before: 64, after: 64 },
                            },
                            1000: {
                                dragThreshold: true,
                                //gap: 0 // 16,
                                perView: 2,
                                peek: { before: 32, after: 32 },
                            },
                            600: {
                                dragThreshold: true,
                                //gap: 0,
                                perView: 1,
                                peek: { before: 16, after: 40 },
                            }
                        },
                    });
                    const slider = document.querySelector(`#${slideId}`);
                    const paragraphs = Array.from(slider.querySelectorAll("p"));
                    const h3Titles = Array.from(slider.querySelectorAll("h3"));
                    const drawerElements = Array.from(slider.querySelectorAll(".drawer"));
                    var images = document.getElementById(slideId);
                    
                    if (images && images.querySelector('.glide__charging-solutions .glide__slides').children.length > 1) {
                        if(images.querySelector('.glide__charging-solutions .glide__slides').children.length > 3) {
                            const $chargingSolutionsBlock = $(`.${slideId}`);
                            $chargingSolutionsBlock.css("padding-right", 0);

                            solutionsGlide.update({
                                peek: { before: 128, after: 128 },
                                dragThreshold: true,
                                breakpoints: {
                                    1300: {
                                        dragThreshold: true,
                                        gap: 40,
                                        perView: 3,
                                        peek: { before: 64, after: 64 },
                                    },
                                    1000: {
                                        dragThreshold: true,
                                        gap: 16,
                                        perView: 2,
                                        peek: { before: 24, after: 32 },
                                    },
                                    600: {
                                        dragThreshold: true,
                                        gap: 16,
                                        perView: 1,
                                        peek: { before: 16, after: 40 },
                                    }
                                },
                            });
                        }
                        solutionsGlide.on("swipe.end", () => {
                            clearTimeout(transition_timeout);
                            paragraphs.forEach(paragraph => paragraph.classList.add("no-trans"));
                            h3Titles.forEach(title => title.classList.add("no-trans"));
                            drawerElements.forEach(drawer => drawer.classList.add("no-events"));
                            transition_timeout = setTimeout(_ => {
                                paragraphs.forEach(paragraph => paragraph.classList.remove("no-trans"));
                                drawerElements.forEach(drawer => drawer.classList.remove("no-events"));
                                h3Titles.forEach(title => title.classList.remove("no-trans"));
                            }, 1200);
                        });

                        solutionsGlide.mount({ Anchors, Breakpoints });
                        
                        var glideNavigation = images.querySelector('.glide__charging-solutions .glide-navigation');

                        solutionsGlide.on('move', function (e) {
                            var items = glideNavigation.querySelectorAll('a');
                            items.forEach(function(item) {
                                item.classList.remove('active');
                            });

                            items[solutionsGlide.index].classList.add('active');
                        });

                        glideNavigation.querySelectorAll('a').forEach(function(link, i) {
                            link.addEventListener('click', function (e) {
                                e.preventDefault();
                                solutionsGlide.go('=' + this.getAttribute('data-id'));
                            });
                        });

                        // Hide/show slideshow navigation on resize
                        function setNavItems(glideNavigation) {

                            for(let i = 0; i < glideNavigation.length; i++) {
                                const navigationContainer = glideNavigation[i];
                
                                if(glideNavigation[i].querySelectorAll('a').length < 4) { 
                                    // Hide navigation if on desktop and there are less than 4 slides
                                    if($( window ).width() >= 1001) {
                                        navigationContainer.style.display = "none";
                
                                        glideNavigation[i].querySelectorAll('a').forEach(function(link, i) {  
                                            link.style.display = "none";
                                        });
                                        solutionsGlide.go("=0");
                                    } else if($( window ).width() >= 601) {
                                        // Show first and every other navigation item on laptop
                                        glideNavigation[i].querySelectorAll('a').forEach(function(link, i) {  
                                            navigationContainer.style.display = "flex";
                                            if(i % 2 === 0) {
                                                link.style.display = "inline-block";
                                            } else {
                                                link.style.display = "none";
                                            }
                                        });
                                    } else {
                                        // Show all navigation items on mobile
                                        glideNavigation[i].querySelectorAll('a').forEach(function(link, i) {  
                                            link.style.display = "inline-block";
                                        }); 
                                    }   
                                } else { 
                                    if($( window ).width() >= 1001) {
                                        // Show first and every fourth navigation item if there are 4 or more slides on desktop
                                        glideNavigation[i].querySelectorAll('a').forEach(function(link, i) {  
                                            if(i % 3 === 0) {
                                                link.style.display = "inline-block";
                                            } else {
                                                link.style.display = "none";
                                            }
                                        });
                                    } else if($( window ).width() >= 601) {
                                        // Show first and every other navigation item on laptop
                                        glideNavigation[i].querySelectorAll('a').forEach(function(link, i) {  
                                            if(i % 2 === 0) {
                                                link.style.display = "inline-block";
                                            } else {
                                                link.style.display = "none";
                                            }
                                        });
                                    } else {
                                        // Show all navigation items on mobile
                                        glideNavigation[i].querySelectorAll('a').forEach(function(link, i) {  
                                            link.style.display = "inline-block";
                                        }); 
                                    }    
                                }            
                            }
                        }

                        // Hide/show slideshow navigation on load and resize
                        $( document ).ready(function() {
                            setNavItems(document.querySelectorAll('.glide__charging-solutions .glide-navigation'));
                            $( window ).resize(function() {
                                setNavItems(document.querySelectorAll('.glide__charging-solutions .glide-navigation'));
                            })
                        }) 
                    }
                    const map = solutionsGlideMultiple.map((item,i) => item.selector === slideIdGlobalSelector ? solutionsGlideMultiple[i] = solutionsGlide : "");
                } else {
                    
                    slideIdGlobal.destroy();
                    var solutionsGlide = new Glide(slideIdGlobalSelector, {
                        type: 'slide',
                        startAt: 0,
                        animationDuration: 1000,
                        gap: 0,
                        perView: 3,
                        dragThreshold: false,
                        peek: { before: 128, after: 128 },
                        breakpoints: {
                            1300: {
                                dragThreshold: false,
                                gap: 0,
                                perView: 3,
                                peek: { before: 64, after: 64 },
                            },
                            1000: {
                                dragThreshold: true,
                                gap: 0,
                                perView: 2,
                                peek: { before: 32, after: 32 },
                            },
                            600: {
                                dragThreshold: true,
                                gap: 0,
                                perView: 1,
                                peek: { before: 16, after: 40 },
                            }
                        },
                    });
                    const slider = document.querySelector(`#${slideId}`);
                    const paragraphs = Array.from(slider.querySelectorAll("p"));
                    const h3Titles = Array.from(slider.querySelectorAll("h3"));
                    const drawerElements = Array.from(slider.querySelectorAll(".drawer"));
                    var images = document.getElementById(slideId);
                    
                    if (images && images.querySelector('.glide__charging-solutions .glide__slides').children.length > 1) {
                        if(images.querySelector('.glide__charging-solutions .glide__slides').children.length > 3) {
                            const $chargingSolutionsBlock = $(`.${slideId}`);
                            $chargingSolutionsBlock.css("padding-right", 0);

                            solutionsGlide.update({
                                peek: { before: 128, after: 128 },
                                dragThreshold: true,
                                breakpoints: {
                                    1300: {
                                        dragThreshold: true,
                                        gap: 0,
                                        perView: 3,
                                        peek: { before: 64, after: 64 },
                                    },
                                    1000: {
                                        dragThreshold: true,
                                        gap: 0,
                                        perView: 2,
                                        peek: { before: 32, after: 32 },
                                    },
                                    600: {
                                        dragThreshold: true,
                                        gap: 0,
                                        perView: 1,
                                        peek: { before: 16, after: 40 },
                                    }
                                },
                            });
                        }
                        solutionsGlide.on("swipe.end", () => {
                            clearTimeout(transition_timeout);
                            paragraphs.forEach(paragraph => paragraph.classList.add("no-trans"));
                            h3Titles.forEach(title => title.classList.add("no-trans"));
                            drawerElements.forEach(drawer => drawer.classList.add("no-events"));
                            transition_timeout = setTimeout(_ => {
                                paragraphs.forEach(paragraph => paragraph.classList.remove("no-trans"));
                                drawerElements.forEach(drawer => drawer.classList.remove("no-events"));
                                h3Titles.forEach(title => title.classList.remove("no-trans"));
                            }, 1200);
                        });

                        solutionsGlide.mount({ Anchors, Breakpoints });
                        
                        var glideNavigation = images.querySelector('.glide__charging-solutions .glide-navigation');

                        solutionsGlide.on('move', function (e) {
                            var items = glideNavigation.querySelectorAll('a');
                            items.forEach(function(item) {
                                item.classList.remove('active');
                            });

                            items[solutionsGlide.index].classList.add('active');
                        });

                        glideNavigation.querySelectorAll('a').forEach(function(link, i) {
                            link.addEventListener('click', function (e) {
                                e.preventDefault();
                                solutionsGlide.go('=' + this.getAttribute('data-id'));
                            });
                        });

                        // Hide/show slideshow navigation on resize
                        function setNavItems(glideNavigation) {

                            for(let i = 0; i < glideNavigation.length; i++) {
                                const navigationContainer = glideNavigation[i];
                
                                if(glideNavigation[i].querySelectorAll('a').length < 4) { 
                                    // Hide navigation if on desktop and there are less than 4 slides
                                    if($( window ).width() >= 1001) {
                                        navigationContainer.style.display = "none";
                
                                        glideNavigation[i].querySelectorAll('a').forEach(function(link, i) {  
                                            link.style.display = "none";
                                        });
                                        solutionsGlide.go("=0");
                                    } else if($( window ).width() >= 601) {
                                        // Show first and every other navigation item on laptop
                                        glideNavigation[i].querySelectorAll('a').forEach(function(link, i) {  
                                            navigationContainer.style.display = "flex";
                                            if(i % 2 === 0) {
                                                link.style.display = "inline-block";
                                            } else {
                                                link.style.display = "none";
                                            }
                                        });
                                    } else {
                                        // Show all navigation items on mobile
                                        glideNavigation[i].querySelectorAll('a').forEach(function(link, i) {  
                                            link.style.display = "inline-block";
                                        }); 
                                    }   
                                } else { 
                                    if($( window ).width() >= 1001) {
                                        // Show first and every fourth navigation item if there are 4 or more slides on desktop
                                        glideNavigation[i].querySelectorAll('a').forEach(function(link, i) {  
                                            if(i % 3 === 0) {
                                                link.style.display = "inline-block";
                                            } else {
                                                link.style.display = "none";
                                            }
                                        });
                                    } else if($( window ).width() >= 601) {
                                        // Show first and every other navigation item on laptop
                                        glideNavigation[i].querySelectorAll('a').forEach(function(link, i) {  
                                            if(i % 2 === 0) {
                                                link.style.display = "inline-block";
                                            } else {
                                                link.style.display = "none";
                                            }
                                        });
                                    } else {
                                        // Show all navigation items on mobile
                                        glideNavigation[i].querySelectorAll('a').forEach(function(link, i) {  
                                            link.style.display = "inline-block";
                                        }); 
                                    }    
                                }            
                            }
                        }

                        // Hide/show slideshow navigation on load and resize
                        $( document ).ready(function() {
                            setNavItems(document.querySelectorAll('.glide__charging-solutions .glide-navigation'));
                            $( window ).resize(function() {
                                setNavItems(document.querySelectorAll('.glide__charging-solutions .glide-navigation'));
                            })
                        }) 
                    }
                    const map = solutionsGlideMultiple.map((item,i) => item.selector === slideIdGlobalSelector ? solutionsGlideMultiple[i] = solutionsGlide : "");
                }   
            } 
        }
    });
}

const drawerDisplay = document.querySelectorAll(".charging-solutions .drawerDisplay");
const drawers = document.querySelectorAll(".charging-solutions .drawer");
const close_buttons = document.querySelectorAll(".charging-solutions .close_drawer");
function openDrawer(i, drawer, e) {
    e.preventDefault();
    if(drawer.getAttribute("href") === "#") {
        document.body.classList.add("stop-scrolling");
        drawerDisplay[i].style.height = "100vh";
        drawerDisplay[i].style.opacity = "1";
        drawerDisplay[i].children[1].style.transform = "translateX(0%)";
    }
}
if(drawers.length !== 0) {
    for(let i = 0; i < drawers.length; i++) {
        drawers[i].addEventListener("click", openDrawer.bind(null,i,drawers[i]));
        drawers[i].addEventListener("touchend", openDrawer.bind(null,i,drawers[i]));
        close_buttons[i].addEventListener("click", function(){
            document.body.classList.remove("stop-scrolling");
            
            setTimeout(() => {drawerDisplay[i].style.height = 0}, 300)
            drawerDisplay[i].style.opacity = "0";
            drawerDisplay[i].children[1].classList.remove("open");
            drawerDisplay[i].children[1].style.transform = "translateX(100%)"
        });
    };
}
 
if(document.querySelectorAll(".charging-solutions .glide__slide").length > 1) {
    const $solutionSections = $(".charging-solutions");

    $solutionSections.each(function( index ) {
        const solutionItems = $(this).find(".glide__slide");

        // Dim non-hovered slideshow elements
        for(let i = 0; i < solutionItems.length; i++) {
            solutionItems[i].addEventListener("mouseover", function(){
                for(let j = 0; j < solutionItems.length; j++) {
                    solutionItems[j].classList.add("dim-non-hovered");
                }
                solutionItems[i].classList.remove("dim-non-hovered");
            });
            solutionItems[i].addEventListener("mouseleave", function(){
                for(let j = 0; j < solutionItems.length; j++) {
                    solutionItems[j].classList.remove("dim-non-hovered");
                }
            });
        };
    });
    
};
